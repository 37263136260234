import {ClientFooter} from "@/components/client/client-footer.jsx";
import {ClientHeader} from "@/components/client/client-header.jsx";
import {Button} from "@/components/ui/button";
import {Toaster} from "@/components/ui/sonner";
import useCookies from "@/components/utils/use-cookies.js";
import {cn} from "@/lib/utils";
import {useStore} from "@/store";
import {ChevronUp} from "lucide-react";
import {useEffect} from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Outlet} from "react-router-dom";

const ClientLayout = () => {
    const companyData = useStore((state) => state.companyData);
    const [showScrollTop, setShowScrollTop] = useState(false);
    const {cookiesAccepted, acceptCookies} = useCookies();
    const {t} = useTranslation();

    useEffect(() => {
        const setColorProperty = (property, color) => {
            document.documentElement.style.setProperty(property, color);
        };
        setColorProperty("--color-primary", companyData?.primaryColor);
        setColorProperty("--color-secondary", companyData?.secondaryColor);
    }, [companyData]);

    useEffect(() => {
        const handleScroll = () => setShowScrollTop(window.scrollY > 200);
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: "smooth"});
    };

    useEffect(() => {
        if (companyData?.title) {
            document.title = `${companyData.title}`;
        } else {
            document.title = "Canal Ético";
        }
    }, [companyData?.name]);

    return (
        <section className="w-full items-center h-screen font-sans flex flex-col">
            <ClientHeader/>
            <section
                className={cn(
                    "p-6 flex  max-w-7xl flex-grow flex-col gap-4 w-full",
                    "md:px-10 md:py-8 md:gap-6 2xl:px-0",
                )}
            >
                <Outlet/>
                <Toaster/>
            </section>
            {showScrollTop && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-6 right-6 animate-fade-in 2xl:right-[1.9rem] bg-primary text-white p-2 rounded-xl shadow-lg"
                    aria-label="Scroll to top"
                >
                    <ChevronUp size={24}/>
                </button>
            )}
            <ClientFooter/>
            {!cookiesAccepted && (
                <div
                    className="fixed bottom-0 left-1/2 transform -translate-x-1/2 w-full border bg-slate-100 py-4 px-5 rounded-t-lg shadow-lg flex items-center justify-between">
                    <span>{t("Cookies.text")}</span>
                    <Button
                        onClick={acceptCookies}
                        className="w-3/4 ml-4 bg-primary text-white p-2 rounded"
                    >
                        {t("buttonTexts.accept")}
                    </Button>
                </div>
            )}
        </section>
    );
};
export default ClientLayout;
