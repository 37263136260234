import axios from "axios";
import { logout } from "./login/login-services";

//URL PRO
const API_URL = "https://api.canaletico.es";
//URL DEV
// const API_URL = "http://dev.canaletico.es";

//CREAMOS INSTANCIA DE AXIOS
const api = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

//INTERCEPTOR PARA AÑADIR EL TOKEN Y LA CABECERA X-Client-URL A LAS PETICIONES
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        // Añadir X-Client-URL con la URL actual del navegador
        const clientUrl = window.location.href;
        config.headers["X-Client-URL"] = clientUrl;

        return config;
    },
    (error) => Promise.reject(error),
);

// INTERCEPTOR PARA MANEJAR RESPUESTAS
api.interceptors.response.use(
    (response) => response,
    (error) => {
        const token = localStorage.getItem("token");
        if (error.response && error.response.status === 401 && token) {
            logout();
        }
        return Promise.reject(error);
    }
);

export default api;
