import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { columns } from "@/components/ui/table/columns/interest-docs";

import { DataTable } from "@/components/ui/table/data-table";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toaster } from "sonner";
import AddInterestHomeFileForm from "./add-interest-home-file-form";

const InterestDocsDataTable = () => {
  const { t } = useTranslation();
  const getHomeFiles = useStore((state) => state.getHomeFiles);
  const homeFiles = useStore((state) => state.homeFiles);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      getHomeFiles();
    } catch (error) {
      console.error("Load home files error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatFiles = (files) => {
    return (files || []).map((file) => ({
      id: file.id,
      title: file.title ? file.title : file.filename,
      document: file.filename,
    }));
  };
  const formattedFiles = formatFiles(homeFiles);

  return (
    <section className="w-full">
      <Toaster />
      <section className="w-full flex mb-3">
        <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
          <DialogTrigger asChild={true}>
            <Button className="mt-4 bg-primary hover:bg-primary-600">
              {t("buttonTexts.addNew")} <Plus className="ml-2" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                {t("MainLayout.interestDocs.createInterestDocs")}
              </DialogTitle>
            </DialogHeader>
            <AddInterestHomeFileForm onClose={() => setIsDialogOpen(false)} />
          </DialogContent>
        </Dialog>
      </section>

      {loading ? (
        <div className="flex justify-center">
          <Loader style={"loader"} />
        </div>
      ) : (
        <DataTable
          data={formattedFiles}
          columns={columns}
          mainFilterColumn="title"
          textPlaceholder={t("filterTextPlaceholders.interestDocs")}
        />
      )}
    </section>
  );
};

export default InterestDocsDataTable;
