import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { ArrowDown, ArrowUp } from "lucide-react";

export function DataTableColumnHeader({ column, title, className }) {
  if (!column.getCanSort()) {
    return <div className={cn("text-center", className)}>{title}</div>; // Añadido `text-center` aquí
  }

  const sortIcon = {
    desc: <ArrowDown className="ml-2 h-4 w-4" />,
    asc: <ArrowUp className="ml-2 h-4 w-4" />,
    default: <CaretSortIcon className="ml-2 h-4 w-4" />,
  };

  return (
    <Button
      variant="ghost"
      onClick={() => column.toggleSorting()}
      className={cn(
        "flex items-center justify-center w-full space-x-2 hover:bg-transparent",
        "text-center", // Añadir `text-center` aquí
        className,
      )}
    >
      <span>{title}</span>
      {sortIcon[column.getIsSorted()] || sortIcon.default}
    </Button>
  );
}
