import {getStatsData} from "@/api/stats/stats-services";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import Loader from "@/components/utils/loader";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import {Calendar as CalendarIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import {cn} from "@/lib/utils";

import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

const StatsPage = () => {
    const current_year = new Date().getFullYear();
    const currentDate = new Date();

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const {t} = useTranslation();
    const [dates, setDates] = useState({
        complaints: {
            startDate: ``,
            endDate: ``,
        },
        //,
        // "complaints_types": {
        //   "startDate": `${current_year}-01-01`,
        //   "endDate": `${current_year}-01-31`
        // },
        // "anonymous": {
        //   "startDate": `${current_year}-01-01`,
        //   "endDate": `${current_year}-01-31`
        // },
        // "states": {
        //   "startDate": `${current_year}-01-01`,
        //   "endDate": `${current_year}-01-31`
        // },
        // "consultations": {
        //   "startDate": `${current_year}-01-01`,
        //   "endDate": `${current_year}-01-31`
        // },
        // "visits": {
        //   "startDate": `${current_year}-01-01`,
        //   "endDate": `${current_year}-01-31`
        // }
    });

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const response = await getStatsData(dates);
            setData(response);
            setLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (fromDate && toDate) {
            setDates((prevDates) => ({
                ...prevDates,
                complaints: {
                    startDate: format(fromDate, "yyyy-MM-dd"),
                    endDate: format(toDate, "yyyy-MM-dd"),
                },
            }));
        }
    }, [fromDate, toDate]);

    useEffect(() => {
        if (fromDate && toDate) {
            const fetchData = async () => {
                const response = await getStatsData(dates);
                setData(response);
            };
            fetchData();
        }
    }, [dates]);

    // Transformar los datos de complaints en el formato adecuado para el gráfico de líneas
    const lineChartComplaints = Object.entries(data.complaints || {}).map(
        ([month, complaints]) => ({
            month,
            complaints,
        }),
    );

    // Transformar los datos de tipo de denuncia en el formato adecuado para el gráfico de torta. NO SE MUESTRA SI NO EXISTE!!
    const pieChartComplaintTypes =
        (data.complaints_types &&
            Object.entries(data.complaints_types).map(([name, value]) => ({
                name,
                value,
            }))) ||
        [];

    // Transformar los datos de anonymous en el formato adecuado para el gráfico de torta. NO SE MUESTRA SI NO EXISTE!!
    const pieChartAnonymous =
        (data.anonymous &&
            Object.entries(data.anonymous).map(([name, value]) => ({
                name,
                value,
            }))) ||
        [];

    // Transformar los datos de consultations en el formato adecuado para el gráfico de torta
    const pieChartConsultations = Object.entries(data.consultations || {}).map(
        ([name, value]) => ({
            name,
            value,
        }),
    );

    // Transformar los datos de states en el formato adecuado para el gráfico de torta
    const pieChartStates = Object.entries(data.states || {}).map(
        ([name, value]) => ({
            name,
            value,
        }),
    );

    // Transformar los datos de visits en el formato adecuado para el gráfico de barras
    const barChartData = Object.entries(data.visits?.visits || {}).map(
        ([month, visits]) => ({
            month,
            visits,
        }),
    );

    const COLORS = [
        "hsl(var(--chart-1))",
        "hsl(var(--chart-2))",
        "hsl(var(--chart-3))",
        "hsl(var(--chart-4))",
        "hsl(var(--chart-5))",
        "hsl(var(--chart-6))",
        "hsl(var(--chart-7))",
        "hsl(var(--chart-8))",
        "hsl(var(--chart-9))",
        "hsl(var(--chart-10))",
    ];

    if (loading) {
        return (
            <div className="flex justify-center h-screen">
                <Loader style={"loader"}/>
            </div>
        );
    }

    return (
        <div className="p-8">
            <section className="mb-8">
                <h1 className="text-3xl font-bold">{t("statsPage.title")}</h1>
            </section>

            <section className="my-4">
                <Card>
                    <CardHeader>
                        <div className="flex flex-wrap justify-between gap-x-4">
                            <div className="">
                                <CardTitle>{t("statsPage.graph1.title")}</CardTitle>
                                <CardDescription>
                                    {t("statsPage.graph1.description")}
                                </CardDescription>
                            </div>
                            <div className="flex space-x-4 mt-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t("statsPage.graph1.dateFrom")}
                                    </label>
                                    <Popover>
                                        <PopoverTrigger asChild={true}>
                                            <Button
                                                variant={"outline"}
                                                className={cn(
                                                    "w-[240px] justify-start text-left font-normal",
                                                    !fromDate && "text-muted-foreground",
                                                )}
                                            >
                                                <CalendarIcon className="mr-2 h-4 w-4"/>
                                                {fromDate ? (
                                                    format(fromDate, "PPP", {locale: es})
                                                ) : (
                                                    <span>{t("statsPage.graph1.dateDescription")}</span>
                                                )}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0" align="start">
                                            <Calendar
                                                mode="single"
                                                locale={es}
                                                selected={fromDate}
                                                onSelect={(date) => {
                                                    setFromDate(date);
                                                    if (toDate && date && toDate < date) {
                                                        setToDate(undefined);
                                                    }
                                                }}
                                                initialFocus={true}
                                                disabled={(date) => {
                                                    if (date > currentDate) return true;
                                                    return false;
                                                }}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>

                                {/* To Date Picker */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t("statsPage.graph1.dateTo")}
                                    </label>
                                    <Popover>
                                        <PopoverTrigger asChild={true}>
                                            <Button
                                                variant={"outline"}
                                                className={cn(
                                                    "w-[240px] justify-start text-left font-normal",
                                                    !toDate && "text-muted-foreground",
                                                )}
                                                disabled={!fromDate}
                                            >
                                                <CalendarIcon className="mr-2 h-4 w-4"/>
                                                {toDate ? (
                                                    format(toDate, "PPP", {locale: es})
                                                ) : (
                                                    <span>{t("statsPage.graph1.dateDescription")}</span>
                                                )}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent className="w-auto p-0" align="start">
                                            <Calendar
                                                mode="single"
                                                selected={toDate}
                                                locale={es}
                                                onSelect={(date) => {
                                                    setToDate(date);
                                                }}
                                                initialFocus={true}
                                                disabled={(date) => {
                                                    if (!fromDate) return true;
                                                    if (date < fromDate) return true;
                                                    if (date > currentDate) return true;
                                                    return false;
                                                }}
                                            />
                                        </PopoverContent>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="h-[400px] w-full">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    data={lineChartComplaints}
                                    margin={{
                                        left: 12,
                                        right: 12,
                                    }}
                                >
                                    <CartesianGrid vertical={false}/>
                                    <XAxis
                                        dataKey="month"
                                        tickLine={false}
                                        axisLine={false}
                                        tickMargin={8}
                                        tickFormatter={(value) => value}
                                    />
                                    <YAxis
                                        stroke="#888888"
                                        fontSize={12}
                                        tickLine={false}
                                        axisLine={false}
                                        tickFormatter={(value) => `${value}`}
                                    />
                                    <Tooltip
                                        formatter={(value, name) => [
                                            value,
                                            name === "complaints" ? t("statsPage.graph1.complaints") : name,
                                        ]}/>
                                    <Line
                                        dataKey="complaints"
                                        type="natural"
                                        stroke="hsl(var(--chart-1))"
                                        strokeWidth={2}
                                        dot={{
                                            fill: "hsl(var(--chart-1))",
                                        }}
                                        activeDot={{
                                            r: 6,
                                        }}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </section>

            {data.complaints_types && pieChartComplaintTypes.length > 0 && (
                <section className="my-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>{t("statsPage.graph2.title")}</CardTitle>
                            <CardDescription>
                                {t("statsPage.graph2.description")}
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="h-[400px] w-full">
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={pieChartComplaintTypes}
                                            cx="50%"
                                            cy="50%"
                                            label={({name, percent}) =>
                                                `${t(`statsPage.graph2.${name}`, {defaultValue: name})} (${(percent * 100).toFixed(0)}%)`
                                            }
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {pieChartComplaintTypes.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip
                                            formatter={(value, name) => [
                                                value,
                                                t(`statsPage.graph2.${name}`, {defaultValue: name}),
                                            ]}
                                        />
                                        <Legend
                                            formatter={(value) => t(`statsPage.graph2.${value}`, {defaultValue: value})}/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>
                </section>
            )}


            {data.anonymous && pieChartAnonymous.length > 0 && (
                <section className="my-4">
                    <Card>
                        <CardHeader>
                            <CardTitle>{t("statsPage.graph3.title")}</CardTitle>
                            <CardDescription>
                                {t("statsPage.graph3.description")}
                            </CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="h-[400px] w-full">
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={pieChartAnonymous}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={true}
                                            label={({name, percent}) =>
                                                `${name} (${(percent * 100).toFixed(0)}%)`
                                            }
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {pieChartAnonymous.map((entry, index) => (
                                                <Cell
                                                    key={`cell-${index}`}
                                                    fill={COLORS[index % COLORS.length]}
                                                />
                                            ))}
                                        </Pie>
                                        <Tooltip/>
                                        <Legend/>
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </CardContent>
                    </Card>
                </section>
            )}

            <section className="my-4">
                <Card>
                    <CardHeader>
                        <CardTitle>{t("statsPage.graph4.title")}</CardTitle>
                        <CardDescription>
                            {t("statsPage.graph4.description")}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="h-[400px] w-full">
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={pieChartConsultations}
                                        cx="50%"
                                        cy="50%"
                                        label={({name, percent}) =>
                                            `${t(`statsPage.graph4.${name}`, {defaultValue: name})} (${(percent * 100).toFixed(0)}%)`
                                        }
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {pieChartConsultations.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[index % COLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip
                                        formatter={(value, name) => [
                                            value,
                                            t(`statsPage.graph4.${name}`, {defaultValue: name}),
                                        ]}
                                    />
                                    <Legend
                                        formatter={(value) => t(`statsPage.graph4.${value}`, {defaultValue: value})}/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </section>


            <section className="my-4">
                <Card>
                    <CardHeader>
                        <CardTitle>{t("statsPage.graph5.title")}</CardTitle>
                        <CardDescription>
                            {t("statsPage.graph5.description")}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="h-[400px] w-full">
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={pieChartStates}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={true}
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="value"
                                        paddingAngle={5}
                                    >
                                        {pieChartStates.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={COLORS[index % COLORS.length]}
                                            />
                                        ))}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </section>

            <section className="my-4">
                <Card>
                    <CardHeader>
                        <CardTitle>{t("statsPage.graph6.title")}</CardTitle>
                        <CardDescription>
                            {t("statsPage.graph6.description")}
                        </CardDescription>
                    </CardHeader>
                    <CardContent>
                        <div className="h-[400px] w-full">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={barChartData}>
                                    <XAxis
                                        dataKey="month"
                                        stroke="#888888"
                                        fontSize={12}
                                        tickLine={false}
                                        axisLine={false}
                                    />
                                    <YAxis
                                        stroke="#888888"
                                        fontSize={12}
                                        tickLine={false}
                                        axisLine={false}
                                        tickFormatter={(value) => `${value}`}
                                    />
                                    <Tooltip
                                        formatter={(value, name) => [
                                            value,
                                            name === "visits" ? t("statsPage.graph6.visits") : name,
                                        ]}
                                    />
                                    <Bar
                                        dataKey="visits"
                                        fill="hsl(var(--primary))"
                                        radius={[4, 4, 0, 0]}
                                    />
                                </BarChart>

                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>
            </section>
        </div>
    );
};
export default StatsPage;
