import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { columns } from "@/components/ui/table/columns/users";
import { DataTable } from "@/components/ui/table/data-table";
import Loader from "@/components/utils/loader.jsx";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toaster } from "sonner";
import CreateUserForm from "./create-user-form.jsx";
import EditUserForm from "./edit-user-form.jsx";

const UsersDataTable = () => {
  const { t } = useTranslation();
  const getUsers = useStore((state) => state.getUsers);
  const getRoles = useStore((state) => state.getRoles);
  const users = useStore((state) => state.users);
  const updateModal = useStore((state) => state.updateModal);
  const setUpdateModal = useStore((state) => state.setUpdateModal);
  const [loading, setLoading] = useState(true);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    try {
      getUsers();
      getRoles();
    } catch (error) {
      console.error("Load users error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatUsers = (users) => {
    return users.map((user) => ({
      id: user.id,
      user: user.email,
      username:
        [user.name, user.lastName].filter(Boolean).join(" ") ||
        "Sin nombre de usuario",
      rol: user.roles.join(", "),
      company: user.company,
      active: user.isActive ? "Sí" : "No",
    }));
  };

  const formattedQuestions = formatUsers(users);

  const handleCloseModal = () => {
    setUpdateModal(false);
  };

  return (
    <section className="w-full">
      <Toaster />
      <section className="w-full flex mb-3">
        <Dialog>
          <DialogTrigger asChild={true}>
            <Button className="mt-4 bg-primary hover:bg-primary-600">
              {t("buttonTexts.addNew")} <Plus className="ml-2" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[625px]  max-h-[90vh] overflow-y-auto">
            <CreateUserForm />
          </DialogContent>
        </Dialog>
      </section>

      <section className="w-full flex">
        <Dialog
          open={updateModal}
          onOpenChange={(isOpen) => setUpdateModal(isOpen)}
        >
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <EditUserForm onClose={handleCloseModal} />
          </DialogContent>
        </Dialog>
      </section>
      {loading ? (
        <div className="flex justify-center">
          <Loader style={"loader"} />
        </div>
      ) : (
        <DataTable
          data={formattedQuestions}
          columns={columns}
          mainFilterColumn="user"
          textPlaceholder={t("filterTextPlaceholders.users")}
        />
      )}
    </section>
  );
};
export default UsersDataTable;
