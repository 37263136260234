import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "@/components/client/breadcrumbs.jsx";
import { ProblemForm } from "@/components/client/forms/problem-form.jsx";

const CreateComplaintPage = () => {
    const { t } = useTranslation();

    return (
        <div className="space-y-4 sm:space-y-6 lg:space-y-10">
            <Breadcrumbs items={[{ title: t("breadcrumb.dashboard"), url: "/dashboard" }]} />
            <h1>{t("MainLayout.createComplaint.title")}</h1>
            <ProblemForm context="admin" />
        </div>
    );
};

export default CreateComplaintPage;
