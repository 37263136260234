"use client";

import { cn } from "@/lib/utils";
import {
  Indicator as RadioGroupIndicator,
  Item as RadioGroupItemPrimitive,
  Root as RadioGroupRoot,
} from "@radix-ui/react-radio-group";
import { Circle } from "lucide-react";
import React from "react";

const RadioGroup = React.forwardRef(({ className, ...props }, ref) => (
  <RadioGroupRoot className={cn(className)} {...props} ref={ref} />
));
RadioGroup.displayName = RadioGroupRoot.displayName;

const RadioGroupItem = React.forwardRef(({ className, ...props }, ref) => (
  <RadioGroupItemPrimitive
    ref={ref}
    className={cn(
      "aspect-square h-4 w-4 rounded-full border border-slate-400 text-primary ring-offset-white focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:opacity-50 dark:text-slate-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
      className,
    )}
    {...props}
  >
    <RadioGroupIndicator className="flex items-center justify-center">
      <Circle className="h-2.5 w-2.5 fill-current text-current" />
    </RadioGroupIndicator>
  </RadioGroupItemPrimitive>
));
RadioGroupItem.displayName = RadioGroupItemPrimitive.displayName;

export { RadioGroup, RadioGroupItem };
