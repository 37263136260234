import { useStore } from "@/store";
import { useEffect } from "react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Skeleton } from "./components/ui/skeleton.jsx";
import AdminProtectedRoute from "./components/utils/admin-protected-route.jsx";
import ProtectedRoutes from "./components/utils/protected-routes.jsx";
import ClientLayout from "./layout/client-layout.jsx";
import MainLayout from "./layout/main-layout.jsx";
import {
  AvailableLanguages,
  ClientPage,
  CommunicationPage,
  CompanySettings,
  ComplaintPage,
  ComplaintsViewsPage,
  EditPage,
  FormPage,
  FormsQuestions,
  InterestDocsDataTable,
  LoginPage,
  MyDataForm,
  PrivacyPolicyDataTable,
  QueriesPage,
  SettingPage,
  StateDataTable,
  StatsPage,
  SummaryPage,
  TagsDataTable,
  TermsPage,
  TwoFactorPage,
  UsersDataTable,
} from "./pages/index.js";
import CreateComplaintPage from "@/pages/complaints/create-complaint-page.jsx";
import CreateQueryPage from "@/pages/queries/create-query-page.jsx";

function App() {
  const loading = useStore((state) => state.loading);
  const getCompanyData = useStore((state) => state.getCompanyData);
  const getConfigurableTexts = useStore((state) => state.getConfigurableTexts);

  useEffect(() => {
    getCompanyData();
    getConfigurableTexts();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col space-y-3">
          <Skeleton className="h-[380px] w-[470px] rounded-xl" />
        </div>
      </div>
    );
  }

  const router = createBrowserRouter([
    // {
    //   path: "/",
    //   element: isAuthenticated() ? (
    //     <Navigate to="/complaints" replace={true} />
    //   ) : (
    //     <Navigate to="/login" replace={true} />
    //   ),
    // },
    {
      path: "/admin",
      element: (
        <ProtectedRoutes>
          <MainLayout />
        </ProtectedRoutes>
      ),
      children: [
        {
          path: "complaints",
          // element: <ComplaintPage />,
          children: [
            {
              path: "",
              element: <ComplaintPage />,
            },
            {
              path: "edit/:id",
              element: <EditPage type="complaint" />,
            },
            {
              path: "create", // Nueva ruta añadida aquí
              element: <CreateComplaintPage />,
            },
          ],
        },
        {
          path: "queries",
          // element: <QueriesPage />,
          children: [
            {
              path: "",
              element: <QueriesPage />,
            },
            {
              path: "edit/:id",
              element: <EditPage type="queries" />,
            },
            {
              path: "create",
              element: <CreateQueryPage />, // Nueva ruta añadida aquí
            },
          ],
        },
        {
          path: "stats",
          element: <StatsPage />,
        },
        {
          path: "edit/:id",
          element: <EditPage />,
        },
        {
          path: "profile",
          element: <MyDataForm />,
        },
        {
          path: "settings",
          element: (
            <AdminProtectedRoute>
              <SettingPage />
            </AdminProtectedRoute>
          ),
          children: [
            {
              index: true,
              element: <Navigate to="users" replace={true} />,
            },
            {
              path: "company",
              element: <CompanySettings />,
            },
            {
              path: "users",
              element: <UsersDataTable />,
            },
            {
              path: "languages",
              element: <AvailableLanguages />,
            },
            {
              path: "complaints-form-details",
              element: <FormsQuestions formType="complaint" />,
            },
            {
              path: "complaints-views-page",
              element: <ComplaintsViewsPage formType={"complaint"} />,
            },
            {
              path: "queries-form-details",
              element: <FormsQuestions formType="consultation" />,
            },
            {
              path: "queries-views-page",
              element: <ComplaintsViewsPage formType={"consultation"} />,
            },
            {
              path: "tags",
              element: <TagsDataTable />,
            },
            {
              path: "policy",
              element: <PrivacyPolicyDataTable />,
            },
            {
              path: "state",
              element: <StateDataTable />,
            },
            {
              path: "interestDocs",
              element: <InterestDocsDataTable />,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/2factor",
      element: <TwoFactorPage />,
    },
    {
      path: "/",
      element: <ClientLayout />,
      children: [
        {
          path: "",
          element: <ClientPage />,
        },
        {
          path: "problem",
          element: <FormPage formType="problem" />,
        },
        {
          path: "inquiry",
          element: <FormPage formType="inquiry" />,
        },
        {
          path: "summary/:id",
          element: <SummaryPage />,
        },
        {
          path: "communication/:id",
          element: <CommunicationPage />,
        },
        {
          path: "terms",
          element: <TermsPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
