import { getCompany } from "@/api/company/company-services"; // Importa la función
import LangSelect from "@/components/lang-select.jsx";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";
import { MenuIcon } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const ClientHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();

  // Estado para almacenar los datos de la empresa
  const [company, setCompany] = useState({
    logo: "",
    mobileLogo: "",
  });

  // Cargar los datos de la empresa cuando el componente se monta
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const companyData = await getCompany();
        setCompany(companyData); // Guarda los datos de la empresa en el estado
      } catch (error) {
        console.error("Error al obtener los datos de la empresa", error);
      }
    };

    fetchCompanyData();
  }, []); // Solo se ejecuta una vez al montar el componente

  const renderNavLinks = (className) =>
    siteConfig.clientNav.map((item, index) => {
      const isActive = location.pathname === item.href;
      return (
        <NavLink
          key={index}
          to={item.href}
          className={cn(
            "transition-colors hover:text-primary",
            isActive && "text-primary",
            className,
          )}
        >
          {t(item.titleKey)}
        </NavLink>
      );
    });

  return (
    <header className="sticky w-full top-0 bg-white flex h-auto items-center justify-between gap-6 border-b px-6 md:px-10 2xl:px-16 p-[10px]">
      <div className="flex w-full justify-between max-w-7xl mx-auto">
        <NavLink to="/">
          <img
              src={company.logo || "/assets/default_logo.png"}
              alt="Company Logo"
              className="hidden md:flex h-auto max-w-[250px]"
          />
          <img
              src={company.logo || "/assets/default_mobile_logo.png"} // Usa el logo de la API o uno por defecto
              alt="Mobile Company Logo"
              className="flex md:hidden w-auto h-10 object-contain"
          />
        </NavLink>
        <nav className="hidden md:flex flex-row items-center gap-6 md:gap-8">
          {renderNavLinks()}
          <LangSelect/>
        </nav>
        <div className="md:hidden ml-auto flex items-center space-x-4">
          <Sheet>
            <SheetTrigger asChild={true}>
              <Button
                variant="outline"
                size="icon"
                className="!p-0 border-none"
              >
                <MenuIcon className="h-6 w-6" />
              </Button>
            </SheetTrigger>
            <SheetContent>
              <div className="grid gap-6">{renderNavLinks()}</div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </header>
  );
};

export { ClientHeader };
