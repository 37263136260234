import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Cross2Icon } from "@radix-ui/react-icons";
import { DataTableFacetedFilter } from "./data-table-faceted-filter.jsx";

export function DataTableToolbar({
  table,
  mainFilterColumn,
  filterOptions,
  textPlaceholder,
}) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const placeholder = textPlaceholder || `Filtrar por ${mainFilterColumn}...`;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder={placeholder}
          value={table.getColumn(mainFilterColumn)?.getFilterValue() ?? ""}
          onChange={(event) =>
            table
              .getColumn(mainFilterColumn)
              ?.setFilterValue(event.target.value)
          }
          className="h-10 w-[150px] lg:w-[250px]"
        />
        {filterOptions?.map((filter) => (
          <DataTableFacetedFilter
            key={filter.column}
            column={table.getColumn(filter.column)}
            title={filter.title}
            options={filter.options}
          />
        ))}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-10 px-2 lg:px-3"
          >
            Reset
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
