import { getLanguages } from "@/api/languages/languages-services";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  title: z.record(
    z.string(),
    z.string().min(1, { message: "Este campo es requerido" }),
  ),
  policyText: z.record(
    z.string(),
    z.string().min(1, { message: "Este campo es requerido" }),
  ),
});

const UpdatePolicyForm = ({ onClose }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [questionLanguages, setQuestionLanguages] = useState([]);
  const updatePrivacyPolicy = useStore((state) => state.updatePrivacyPolicy);
  const policyToUpdate = useStore((state) => state.policyToUpdate);
  const [loading, setLoading] = useState(false);

  const [localData, setLocalData] = useState({
    title: {},
    policyText: {},
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: undefined,
      policyText: undefined,
    },
  });

  useEffect(() => {
    if (policyToUpdate) {
      //CONFIGURAR VALORES INICIALES
      const title = policyToUpdate?.preTexts.reduce((acc, item) => {
        acc[item.language] = item.text;
        return acc;
      }, {});

      const policyText = policyToUpdate?.postTexts.reduce((acc, item) => {
        acc[item.language] = item.text;
        return acc;
      }, {});

      setLocalData({ title, policyText });
      form.reset({ title, policyText });
    }
  }, [policyToUpdate, form]);

  //ACTUALIZAR ESTADO LOCAL
  const handleFieldChange = (fieldName, value) => {
    setLocalData((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        [selectedLanguage]: value,
      },
    }));
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages = await getLanguages();
        setQuestionLanguages(languages);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    const updatedPrivacyPolicy = {
      type: "Privacy",
      preTexts: localData.title,
      postTexts: localData.policyText,
    };
    try {
      await updatePrivacyPolicy(policyToUpdate.id, updatedPrivacyPolicy);
      toast.success(t("alertDialog.toast.policyUpdatedSuccess"));
      onClose();
    } catch (error) {
      console.error("Error al actualizar la política de privacidad:", error);
      toast.error(t("alertDialog.toast.policyUpdatedError"));
    } finally {
      setLoading(false);
    }
  };
  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.privacyPoicy.language")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      setSelectedLanguage(value);
                    }}
                    value={selectedLanguage}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleccionar idioma" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {questionLanguages.map((language) => (
                        <SelectItem
                          key={language.id}
                          value={language.code.toString()}
                        >
                          {language.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`title.${selectedLanguage}`}
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>
                    {t("forms.privacyPoicy.title")} (
                    {selectedLanguage.toUpperCase()})
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      value={localData.title[selectedLanguage] || ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleFieldChange("title", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`policyText.${selectedLanguage}`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("forms.privacyPoicy.description")} (
                    {selectedLanguage.toUpperCase()})
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      {...field}
                      value={localData.policyText[selectedLanguage] || ""}
                      onChange={(e) => {
                        field.onChange(e);
                        handleFieldChange("policyText", e.target.value);
                      }}
                      placeholder="Ingrese la política de privacidad aquí"
                      className="min-h-[150px]"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.saveChanges")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};

export default UpdatePolicyForm;
