"use client";

import {
  CheckboxItem as MenubarCheckboxItemPrimitive,
  Content as MenubarContentPrimitive,
  Group as MenubarGroup,
  ItemIndicator as MenubarItemIndicatorPrimitive,
  Item as MenubarItemPrimitive,
  Label as MenubarLabelPrimitive,
  Menu as MenubarMenu,
  Portal as MenubarPortalPrimitive,
  RadioGroup as MenubarRadioGroup,
  RadioItem as MenubarRadioItemPrimitive,
  Root as MenubarRoot,
  Separator as MenubarSeparatorPrimitive,
  Sub as MenubarSub,
  SubContent as MenubarSubContentPrimitive,
  SubTrigger as MenubarSubTriggerPrimitive,
  Trigger as MenubarTriggerPrimitive,
} from "@radix-ui/react-menubar";
import { Check, ChevronRight, Circle } from "lucide-react";
import { forwardRef } from "react";

import { cn } from "@/lib/utils";
import { MenubarPortal } from "@radix-ui/react-menubar";

const Menubar = forwardRef(({ className, ...props }, ref) => (
  <MenubarRoot
    ref={ref}
    className={cn(
      "flex h-10 items-center space-x-1 rounded-md bg-white p-1 dark:border-slate-800 dark:bg-slate-950",
      className,
    )}
    {...props}
  />
));
Menubar.displayName = MenubarRoot.displayName;

const MenubarTrigger = forwardRef(({ className, ...props }, ref) => (
  <MenubarTriggerPrimitive
    ref={ref}
    className={cn(
      "flex cursor-default select-none items-center rounded-sm px-3 py-1.5 text-sm font-medium outline-none focus:bg-slate-100 focus:text-slate-900 data-[state=open]:bg-slate-100 data-[state=open]:text-slate-900 dark:focus:bg-slate-800 dark:focus:text-slate-50 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-50",
      className,
    )}
    {...props}
  />
));
MenubarTrigger.displayName = MenubarTriggerPrimitive.displayName;

const MenubarSubTrigger = forwardRef(
  ({ className, inset, children, ...props }, ref) => (
    <MenubarSubTriggerPrimitive
      ref={ref}
      className={cn(
        "flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none focus:bg-slate-100 focus:text-slate-900 data-[state=open]:bg-slate-100 data-[state=open]:text-slate-900 dark:focus:bg-slate-800 dark:focus:text-slate-50 dark:data-[state=open]:bg-slate-800 dark:data-[state=open]:text-slate-50",
        inset && "pl-8",
        className,
      )}
      {...props}
    >
      {children}
      <ChevronRight className="ml-auto h-4 w-4" />
    </MenubarSubTriggerPrimitive>
  ),
);
MenubarSubTrigger.displayName = MenubarSubTriggerPrimitive.displayName;

const MenubarSubContent = forwardRef(({ className, ...props }, ref) => (
  <MenubarSubContentPrimitive
    ref={ref}
    className={cn(
      "z-50 min-w-[8rem] overflow-hidden rounded-md border border-slate-200 bg-white p-1 text-slate-950 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50",
      className,
    )}
    {...props}
  />
));
MenubarSubContent.displayName = MenubarSubContentPrimitive.displayName;

const MenubarContent = forwardRef(
  (
    { className, align = "end", alignOffset = -4, sideOffset = 8, ...props },
    ref,
  ) => (
    <MenubarPortalPrimitive>
      <MenubarContentPrimitive
        ref={ref}
        align={align}
        alignOffset={alignOffset}
        sideOffset={sideOffset}
        className={cn(
          "z-50 min-w-[12rem] overflow-hidden rounded-md border border-slate-200 bg-white p-1 text-slate-950 shadow-sm data-[state=open]:animate-in data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-slate-800 dark:bg-slate-950 dark:text-slate-50",
          className,
        )}
        {...props}
      />
    </MenubarPortalPrimitive>
  ),
);
MenubarContent.displayName = MenubarContentPrimitive.displayName;

const MenubarItem = forwardRef(({ className, inset, ...props }, ref) => (
  <MenubarItemPrimitive
    ref={ref}
    className={cn(
      "relative flex cursor-default select-none items-center rounded-md px-2 py-1.5 text-sm outline-none focus:bg-slate-100 focus:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-slate-800 dark:focus:text-slate-50",
      inset && "pl-8",
      className,
    )}
    {...props}
  />
));
MenubarItem.displayName = MenubarItemPrimitive.displayName;

const MenubarCheckboxItem = forwardRef(
  ({ className, children, checked, ...props }, ref) => (
    <MenubarCheckboxItemPrimitive
      ref={ref}
      className={cn(
        "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-slate-100 focus:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-slate-800 dark:focus:text-slate-50",
        className,
      )}
      checked={checked}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <MenubarItemIndicatorPrimitive>
          <Check className="h-4 w-4" />
        </MenubarItemIndicatorPrimitive>
      </span>
      {children}
    </MenubarCheckboxItemPrimitive>
  ),
);
MenubarCheckboxItem.displayName = MenubarCheckboxItemPrimitive.displayName;

const MenubarRadioItem = forwardRef(
  ({ className, children, ...props }, ref) => (
    <MenubarRadioItemPrimitive
      ref={ref}
      className={cn(
        "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-sm outline-none focus:bg-slate-100 focus:text-slate-900 data-[disabled]:pointer-events-none data-[disabled]:opacity-50 dark:focus:bg-slate-800 dark:focus:text-slate-50",
        className,
      )}
      {...props}
    >
      <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
        <MenubarItemIndicatorPrimitive>
          <Circle className="h-2 w-2 fill-current" />
        </MenubarItemIndicatorPrimitive>
      </span>
      {children}
    </MenubarRadioItemPrimitive>
  ),
);
MenubarRadioItem.displayName = MenubarRadioItemPrimitive.displayName;

const MenubarLabel = forwardRef(({ className, inset, ...props }, ref) => (
  <MenubarLabelPrimitive
    ref={ref}
    className={cn(
      "px-2 py-1.5 text-sm font-semibold",
      inset && "pl-8",
      className,
    )}
    {...props}
  />
));
MenubarLabel.displayName = MenubarLabelPrimitive.displayName;

const MenubarSeparator = forwardRef(({ className, ...props }, ref) => (
  <MenubarSeparatorPrimitive
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-slate-100 dark:bg-slate-800", className)}
    {...props}
  />
));
MenubarSeparator.displayName = MenubarSeparatorPrimitive.displayName;

const MenubarShortcut = ({ className, ...props }) => (
  <span
    className={cn(
      "ml-auto text-xs tracking-widest text-slate-500 dark:text-slate-400",
      className,
    )}
    {...props}
  />
);
MenubarShortcut.displayName = "MenubarShortcut";

export {
  Menubar,
  MenubarCheckboxItem,
  MenubarContent,
  MenubarGroup,
  MenubarItem,
  MenubarLabel,
  MenubarMenu,
  MenubarPortal,
  MenubarRadioGroup,
  MenubarRadioItem,
  MenubarSeparator,
  MenubarShortcut,
  MenubarSub,
  MenubarSubContent,
  MenubarSubTrigger,
  MenubarTrigger,
};
