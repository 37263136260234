import { useEffect, useState } from "react";
import { Toaster as Sonner } from "sonner";

const Toaster = ({ ...props }) => {
  const [position, setPosition] = useState("bottom-left");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setPosition("top-left");
      } else {
        setPosition("bottom-left");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Sonner
      theme="light"
      className="md:relative toaster group mt-16 md:mt-20"
      style={{ "--offset": "0px" }}
      toastOptions={{
        classNames: {
          toast:
            "rounded-lg md:min-w-[28rem] group border toast group-[.toaster]:shadow-sm",
          description: "group-[.toast]:text-slate-500",
          actionButton:
            "group-[.toast]:bg-slate-900 group-[.toast]:text-slate-50",
          cancelButton:
            "group-[.toast]:bg-slate-100 group-[.toast]:text-slate-500",
        },
      }}
      position={position}
      richColors={true}
      {...props}
    />
  );
};

export { Toaster };
