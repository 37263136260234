import {
  createPrivacyPolicy,
  deletePrivacyPolicy,
  getPrivacyPolicy,
  getPrivacyPolicyById,
  updatePrivacyPolicy,
} from "@/api/privacy-policy/privacy-policy-services";

export const privacyPolicySlice = (set, get) => ({
  privacyPolicies: [],
  updateModal: false,
  policyToUpdate: {},

  getPrivacyPolicies: async () => {
    const data = await getPrivacyPolicy();
    set({ privacyPolicies: data });
  },

  createPrivacyPolicy: async (policy) => {
    await createPrivacyPolicy(policy);
    get().getPrivacyPolicies();
  },

  deletePrivacyPolicy: async (id) => {
    await deletePrivacyPolicy(id);
    get().getPrivacyPolicies();
  },

  setUpdateModal: (value) => {
    set((state) => ({
      updateModal: value,
      policyToUpdate: value ? state.policyToUpdate : {},
    }));
  },

  getPolicyToUpdate: async (id) => {
    const data = await getPrivacyPolicyById(id);
    set({ policyToUpdate: data });
    return data;
  },

  updatePrivacyPolicy: async (id, policy) => {
    await updatePrivacyPolicy(id, policy);
    get().getPrivacyPolicies();
  },
});
