import {
  CreateUser,
  DeleteUser,
  UpdateUser,
  getRoles,
  getUser,
  getUsers,
} from "@/api/users/users-services";

export const usersSlice = (set, get) => ({
  loggedInEmailUser: "",
  loggedInUser: {},
  users: [],
  rolOptions: [],
  updateModal: false,
  userToUpdateData: {},

  setLoggedInEmailUser: (email) => {
    set({ loggedInEmailUser: email });
  },

  setLoggedInUser: (user) => {
    set({ loggedInUser: user });
  },

  updateLoggedInUser: async (id, userData) => {
    await UpdateUser(id, userData);
    const updatedUser = await getUser(id);
    set({ loggedInUser: updatedUser });
  },

  getUsers: async () => {
    const data = await getUsers();
    set({ users: data });
    return data;
  },

  getRoles: async () => {
    const data = await getRoles();
    set({ rolOptions: data });
    return data;
  },

  createUser: async (userData) => {
    await CreateUser(userData);
    get().getUsers();
  },

  deleteUser: async (id) => {
    await DeleteUser(id);
    get().getUsers();
  },

  setUpdateModal: (value) => {
    set((state) => ({
      updateModal: value,
      userToUpdateData: value ? state.userToUpdateData : {},
    }));
  },

  getUserToUpdateData: async (id) => {
    const data = await getUser(id);
    set({ userToUpdateData: data });
    return data;
  },

  updateUser: async (id, userData) => {
    await UpdateUser(id, userData);
    get().getUsers();
  },
});
