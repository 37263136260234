import { Alert } from "@/components/client/alert";
import { Breadcrumbs } from "@/components/client/breadcrumbs";
import { InquiryForm } from "@/components/client/forms/inquiry-form";
import { ProblemForm } from "@/components/client/forms/problem-form";
import { siteConfig } from "@/config/site";
import { useStore } from "@/store";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const FormPage = ({ formType }) => {
  const { t, i18n } = useTranslation();
  const companyData = useStore((state) => state.companyData);
  const configurableTexts = useStore((state) => state.configurableTexts);
  const [activeTexts, setActiveTexts] = useState({ preText: "", postText: "" });

  useEffect(() => {
    if (!configurableTexts.length) return;

    const getActiveText = (texts) =>
      texts.find((text) => text.language === i18n.language)?.text || "";
    const homeTexts = configurableTexts.find((text) => text.type === "Form");

    setActiveTexts({
      preText: getActiveText(homeTexts.preTexts),
      postText: getActiveText(homeTexts.postTexts),
    });
  }, [configurableTexts, i18n.language]);

  const FormComponent = formType === "problem" ? ProblemForm : InquiryForm;

  return (
    <React.Fragment>
      <Breadcrumbs items={siteConfig.clientNav} />
      <Alert type="info">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeTexts?.preText),
          }}
        />
      </Alert>
      <FormComponent />
      <Alert type="warning">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeTexts?.postText),
          }}
        />
      </Alert>
    </React.Fragment>
  );
};

export default FormPage;
