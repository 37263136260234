import api from "../api.js";

export const getLogEventsById = async (id) => {
  try {
    const response = await api.get(`/log-events/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los textos configurables:", error);
    throw error;
  }
};

export const getCommunicationById = async (id) => {
  try {
    // Verificar si el id es numérico
    const isNumericId = /^\d+$/.test(id);

    // Construir el endpoint basado en el tipo de id
    const endpoint = isNumericId
        ? `/communications/${id}`
        : `/communications/view/${id}`;

    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la comunicación:", error);
    throw error;
  }
};


export const getComplaintsExcel = async () => {
  try {
    const response = await api.get(`/communications/excel?type=complaint`, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los textos configurables:", error);
    throw error;
  }
};

export const getConsultationsExcel = async () => {
  try {
    const response = await api.get(`/communications/excel?type=consultation`, {
      headers: {
        Accept:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los textos configurables:", error);
    throw error;
  }
};

export const updateCommunication = async (id, data) => {
  try {
    const response = await api.put(`/communications/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar la política de privacidad:", error);
    throw error;
  }
};

//POST:CREAR COMENTARIOS
export const createComment = async (data) => {
  try {
    const response = await api.post("/comments", data);
    return response.data;
  } catch (error) {
    console.error("Error al crear el comentario:", error);
    throw error;
  }
};

//DELETE: ELIMINAR COMENTARIOS
export const deleteComment = async (id) => {
  try {
    const response = await api.delete(`/comments/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el comentario:", error);
    throw error;
  }
};

export default {
  createComment,
  deleteComment,
  getLogEventsById,
  getCommunicationById,
};
