// src/components/ui/toast.jsx

import { useEffect } from "react";

const Toast = ({ message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 5000); // Cerrar el toast después de 5 segundos
    return () => clearTimeout(timer);
  }, [onClose]);

  if (!message) return null;

  return (
    <div className="fixed top-4 right-4 bg-red-500 text-white p-4 rounded shadow-lg">
      {message}
      <button onClick={onClose} className="ml-4 text-white font-bold">
        ✕
      </button>
    </div>
  );
};

export default Toast;
