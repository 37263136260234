import {
  deleteFile,
  getFiles,
  uploadFile,
  uploadHomeFile,
} from "@/api/client/communications";
import {
  createComment,
  deleteComment,
  getCommunicationById,
} from "@/api/edit-comunications/edit-comunications";

export const editSlice = (set, get) => ({
  currentCommunication: {},
  homeFiles: [],
  clearCurrentCommunication: () => set({ currentCommunication: null }),
  getCommunicationById: async (id) => {
    const currentCommunication = await getCommunicationById(id);
    set({ currentCommunication });
  },

  createComment: async (values, id) => {
    await createComment(values);
    get().getCommunicationById(id);
  },

  deleteComment: async (id, communicationId) => {
    await deleteComment(id);
    get().getCommunicationById(communicationId);
  },

  uploadFile: async (file, communicationId, isPublic, type) => {
    await uploadFile(file, communicationId, isPublic, type);
    get().getCommunicationById(communicationId);
  },

  deleteFile: async (id, communicationId) => {
    await deleteFile(id);
    get().getCommunicationById(communicationId);
  },

  getHomeFiles: async () => {
    const allFiles = await getFiles();
    const homeFiles = allFiles.filter((file) => file.type === "home");
    set({ homeFiles });
  },

  uploadHomeFile: async (file, type, title, isPublic, language) => {
    await uploadHomeFile(file, type, title, isPublic, language);
    get().getHomeFiles();
  },

  deleteHomeFile: async (id) => {
    await deleteFile(id);
    get().getHomeFiles();
  },
});
