import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";

import { useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
  code: z.string().min(2, { message: "Code is required" }).max(2),
  name: z.string().min(2, { message: "Nombre is required" }),
});

const ComplaintTypeForm = ({ onSubmit }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      code: "",
      name: "",
    },
  });

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("ClientLayout.forms.languages.code")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("ClientLayout.forms.languages.code")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t("ClientLayout.forms.languages.name")}
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("ClientLayout.forms.languages.name")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {t("buttonTexts.create")}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};
export default ComplaintTypeForm;
