import { getLanguages } from "@/api/languages/languages-services";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  texts: z.record(
    z.string(),
    z.string().min(1, { message: "Este campo es requerido" }),
  ),
  descriptions: z.record(z.string(), z.string().optional()),
  placeholders: z.record(z.string(), z.string().optional()),
  position: z.number().min(1, { message: "Position is required" }),
  type: z.string().min(1, { message: "Este campo es requerido" }),
  optionsText: z.record(z.string(), z.array(z.string().optional())).optional(),
  active: z.boolean().optional(),
  required: z.boolean().optional(),
  depends_id: z.number().nullable().optional(),
});

const CreateQuestion = ({ formType }) => {
  const { t } = useTranslation();
  const [questionLanguages, setQuestionLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const complaintQuestions = useStore((state) => state.complaintQuestions);
  const consultationQuestions = useStore(
    (state) => state.consultationQuestions,
  );

  const createQuestion = useStore((state) => state.createQuestion);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      texts: {},
      descriptions: {},
      placeholders: {},
      position: 0,
      type: "",
      optionsText: {},
      active: false,
      required: false,
      depends_id: null,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: `optionsText.${selectedLanguage}`,
  });

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages = await getLanguages();
        setQuestionLanguages(languages);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  const addOption = () => {
    append("");
  };

  const getTextByLanguage = (texts, language) => {
    if (!Array.isArray(texts)) {
      return "";
    }
    const textObj = texts.find((text) => text.language === language);
    return textObj ? textObj.text : "";
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await createQuestion(values, formType);
      toast.success(t("alertDialog.toast.questionCreatedSuccess"));
      form.reset();
    } catch (error) {
      console.error("Error al crear la pregunta:", error);
      toast.error(t("alertDialog.toast.questionCreatedError"));
    } finally {
      setLoading(false);
    }
  };

  const questions =
    formType === "complaint" ? complaintQuestions : consultationQuestions;

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full text-black bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.privacyPoicy.language")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      setSelectedLanguage(value);
                    }}
                    value={selectedLanguage}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleccionar idioma" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {questionLanguages.map((language) => (
                        <SelectItem
                          key={language.id}
                          value={language.code.toString()}
                        >
                          {language.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {selectedLanguage && (
              <>
                <FormField
                  control={form.control}
                  name={`texts.${selectedLanguage}`}
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.question")} (
                        {selectedLanguage.toUpperCase()})
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`descriptions.${selectedLanguage}`}
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.description")}(
                        {selectedLanguage.toUpperCase()})
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name={`placeholders.${selectedLanguage}`}
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.placeholder")} (
                        {selectedLanguage.toUpperCase()})
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}

            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2 space-y-0 mb-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="active"
                    />
                  </FormControl>
                  <FormLabel
                    htmlFor="active"
                    className="text-sm font-medium leading-none cursor-pointer"
                  >
                    {t("forms.questions.activate")}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="required"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2 space-y-0 mb-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="required"
                    />
                  </FormControl>
                  <FormLabel
                    htmlFor="required"
                    className="text-sm font-medium leading-none cursor-pointer"
                  >
                    {t("forms.questions.required")}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="position"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.questions.position")}</FormLabel>
                  <Select
                    onValueChange={(value) => field.onChange(Number(value))}
                    value={field.value ? field.value.toString() : ""}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select position" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="1">
                        {t("forms.questions.screen")} 1
                      </SelectItem>
                      <SelectItem value="2">
                        {t("forms.questions.screen")} 2
                      </SelectItem>
                      <SelectItem value="3">
                        {t("forms.questions.screen")} 3
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.questions.questionType")}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Selecciona tipo de pregunta" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="select">Select</SelectItem>
                      <SelectItem value="radio">Radio</SelectItem>
                      <SelectItem value="input">Input</SelectItem>
                      <SelectItem value="textArea">TextArea</SelectItem>
                      <SelectItem value="email">Email</SelectItem>
                      <SelectItem value="terms">Terminos</SelectItem>
                      <SelectItem value="file">Fichero</SelectItem>
                      <SelectItem value="password">Contraseña</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {(form.watch("type") === "select" ||
              form.watch("type") === "radio") && (
              <div className="space-y-4">
                <div className="flex justify-between items-center mt-2">
                  <FormLabel>{t("forms.questions.options")}</FormLabel>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={addOption}
                  >
                    {t("forms.questions.addOption")}
                  </Button>
                </div>

                {fields.map((field, index) => (
                  <div key={field.id} className="flex space-x-2">
                    <FormField
                      control={form.control}
                      name={`optionsText.${selectedLanguage}.${index}`}
                      render={({ field }) => (
                        <FormItem className="flex-1 mb-4">
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={`${selectedLanguage.toUpperCase()} ${t("forms.questions.option")}`}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                    <Button
                      type="button"
                      variant="destructive"
                      size="sm"
                      onClick={() => remove(index)}
                    >
                      {t("forms.questions.remove")}
                    </Button>
                  </div>
                ))}

                <FormField
                  control={form.control}
                  name="related_question"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>
                        {t("forms.questions.relatedQuestion")}
                      </FormLabel>
                      <Select
                        onValueChange={(value) => {
                          const selectedQuestion = questions.find(
                            (question) => question.id === Number(value),
                          );
                          field.onChange(selectedQuestion);
                          setSelectedQuestion(selectedQuestion);
                        }}
                        value={field.value?.id || ""}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Selecciona la pregunta relacionada" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {questions
                            .filter(
                              (question) =>
                                question.type === "radio" ||
                                question.type === "select",
                            )
                            .map((question) => (
                              <SelectItem key={question.id} value={question.id}>
                                {getTextByLanguage(
                                  question.texts,
                                  selectedLanguage,
                                ) || question.alias}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {selectedQuestion && (
                  <FormField
                    control={form.control}
                    name="depends_id"
                    render={({ field }) => (
                      <FormItem className="mb-4">
                        <FormLabel>
                          {t("forms.questions.optionRelated")}
                        </FormLabel>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(Number(value));
                          }}
                          value={field.value || ""}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Selecciona la opción relacionada" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {selectedQuestion.options.map((option) => (
                              <SelectItem key={option.id} value={option.id}>
                                {getTextByLanguage(
                                  option.text,
                                  selectedLanguage,
                                ) || "no tiene opciones"}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </div>
            )}

            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.create")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};

export default CreateQuestion;
