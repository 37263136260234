import {problemForm} from "@/api/client/forms";
import {DynamicForm} from "@/components/client/dynamic-form";
import {Form} from "@/components/ui/form";
import {
    Steps,
    StepsContent,
    StepsList,
    StepsTrigger,
} from "@/components/ui/stepper";
import {
    cn,
    generateDefaultValues,
    processDefaultValues,
    shouldRenderField, // Importamos `shouldRenderField` para usar en la validación
} from "@/lib/utils";
import {useStore} from "@/store";
import React, {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Toaster, toast} from "sonner";

const TABS = [
    {value: 1, label: "ClientLayout.forms.stepper.first"},
    {value: 2, label: "ClientLayout.forms.stepper.second"},
    {value: 3, label: "ClientLayout.forms.stepper.third"},
];

const StepItem = ({tab, currentTab, onTabChange}) => {
    const {t} = useTranslation();

    return (
        <div className="flex items-center">
            <StepsTrigger
                value={tab.value}
                onClick={() => onTabChange(tab.value)}
                className={cn(
                    [2, 3].includes(currentTab) &&
                    tab.value <= currentTab &&
                    "bg-primary text-white border-primary",
                )}
            >
                {tab.value}
            </StepsTrigger>
            <span
                className={cn(
                    "mx-5 hidden md:flex transition-all",
                    tab.value === 3 && "!ml-5 mx-0",
                    tab.value <= currentTab && "text-primary",
                )}
            >
        {t(tab.label)}
      </span>
        </div>
    );
};

const StepDivider = ({currentTab, index}) => (
    <div className="flex-1 flex items-center">
        <hr
            className={cn(
                "border-t border-gray-100 flex-1 mx-5 md:ml-0 transition-all",
                ((currentTab === 2 && index === 0) ||
                    (currentTab === 3 && index <= 1)) &&
                "border-primary",
            )}
        />
    </div>
);

export const ProblemForm = ({values, context = "public"}) => {
    const {i18n} = useTranslation();
    const uploadFile = useStore((state) => state.uploadFile);
    const [questions, setQuestions] = useState([]);
    const [defaultValues, setDefaultValues] = useState(null);
    const [currentTab, setCurrentTab] = useState(1);
    const createCommunication = useStore((state) => state.createCommunication);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const form = useForm({
        defaultValues: defaultValues,
    });

    const onSubmit = useCallback(
        async (values, _token) => {
            try {
                const {confirmPassword, files, ...filteredValues} = values;
                const language = i18n.language;

                // Crear la comunicación primero
                const communication = await createCommunication({
                    type: "complaint",
                    risk: "medium",
                    language: language,
                    ...filteredValues,
                });
                // Subir los archivos usando el ID de la comunicación
                if (files && files.length) {
                    const uploadPromises = Array.from(files).map(file =>
                        uploadFile(file, communication.id, 'public', 'communication', null, true)
                    );
                    await Promise.all(uploadPromises);
                }

                form.reset(defaultValues);
                if (context === "admin") {
                    navigate(`/admin/complaints`);
                } else {
                    navigate(`/summary/${communication.accessToken}`);
                }

            } catch (error) {
                toast.error(t("ClientLayout.forms.problem.error", error));
            }
        },
        [t, form, uploadFile, createCommunication],
    );

    // Función de validación para los campos visibles en la pestaña actual
    const validateFields = (currentTab) => {
        form.clearErrors(); // Limpiar errores previos
        const values = form.getValues(); // Obtener valores actuales del formulario
        let isValid = true;
        const newErrors = {};

        // Filtrar las preguntas de tipo password en el formulario
        const passwordQuestions = questions.filter((q) => q.type === "password");

        questions
            .filter((question) => question.screen === currentTab) // Solo preguntas de la pestaña actual
            .filter((question) => shouldRenderField(question, values, questions)) // Solo preguntas visibles
            .forEach((question) => {
                const value = values[question.alias];
                newErrors[question.alias] = "";

                if (
                    question.required &&
                    (!value || (typeof value === "string" && value.trim() === ""))
                ) {
                    newErrors[question.alias] = "Este campo es obligatorio";
                    isValid = false;
                } else if (
                    question.type === "email" &&
                    value &&
                    !/^\S+@\S+\.\S+$/.test(value)
                ) {
                    newErrors[question.alias] = "Introduce un email válido";
                    isValid = false;
                } else if (question.type === "password") {
                    // Validación de longitud mínima
                    if (question.required && (!value || value.length < 6)) {
                        newErrors[question.alias] =
                            "La contraseña debe tener al menos 6 caracteres";
                        isValid = false;
                    }
                }

                if (newErrors[question.alias]) {
                    console.log(
                        `Validation failed for ${question.alias}:`,
                        newErrors[question.alias],
                    );
                }
            });

        // Establecer errores individualmente en `form`
        Object.entries(newErrors).forEach(([field, errorMessage]) => {
            if (errorMessage) {
                form.setError(field, {type: "manual", message: errorMessage});
            }
        });

        return isValid;
    };

    const onTabChange = async (newTab) => {
        const isValid = validateFields(currentTab); // Valida solo la pestaña actual
        if (isValid) {
            setCurrentTab(newTab); // Cambia de pestaña si es válida
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const formData = await problemForm();
            const questions = formData.questions;

            const filteredQuestions = questions?.filter(
                (question) => question.visibility?.form === true,
            );

            setQuestions(filteredQuestions);
            const processedValues = processDefaultValues(values);
            const defaultValues =
                processedValues || generateDefaultValues(filteredQuestions);
            setDefaultValues(defaultValues);
        };
        fetchData();
    }, [t]);

    useEffect(() => {
        if (defaultValues) {
            form.reset(defaultValues);
        }
    }, [defaultValues, form]);

    return (
        questions.length > 0 && (
            <div className="border border-dashed rounded-lg p-6 border-primary animate-fade-in">
                <Toaster/>
                <Form {...form}>
                    <Steps className="w-full" value={currentTab}>
                        <StepsList className="flex w-full justify-between items-center mb-4">
                            {TABS.filter((tab) =>
                                questions.some((q) => q.screen === tab.value),
                            ).map((tab, index) => (
                                <React.Fragment key={index}>
                                    <StepItem
                                        tab={tab}
                                        currentTab={currentTab}
                                        onTabChange={onTabChange}
                                    />
                                    {index < 2 && (
                                        <StepDivider currentTab={currentTab} index={index}/>
                                    )}
                                </React.Fragment>
                            ))}
                        </StepsList>
                        {TABS.map((tab) => (
                            <StepsContent key={tab.value} value={tab.value}>
                                <DynamicForm
                                    questions={questions.filter((q) => q.screen === tab.value)}
                                    form={form}
                                    onSubmit={onSubmit}
                                    currentTab={currentTab}
                                    totalTabs={TABS.length}
                                    onPrevious={() => onTabChange(currentTab - 1)}
                                    onNext={() => onTabChange(currentTab + 1)}
                                    disabled={!!values}
                                    view={values ? "summary" : "form"}
                                    context={context}
                                />
                            </StepsContent>
                        ))}
                    </Steps>
                </Form>
            </div>
        )
    );
};
