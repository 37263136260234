import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import {
  appSlice,
  communicationSlice,
  companySlice,
  editSlice,
  formsQuestionsSlice,
  languageListSlice,
  privacyPolicySlice,
  statesSlice,
  tagsSlice,
  usersSlice,
} from "./slices/index.js";

export const useStore = create()(
  devtools(
    persist(
      (...a) => ({
        ...appSlice(...a),
        ...companySlice(...a),
        ...communicationSlice(...a),
        ...formsQuestionsSlice(...a),
        ...privacyPolicySlice(...a),
        ...statesSlice(...a),
        ...tagsSlice(...a),
        ...usersSlice(...a),
        ...languageListSlice(...a),
        ...editSlice(...a),
      }),
      {
        name: "store",

        // Guardar el estado directamente como JSON sin compresión
        serialize: (state) => JSON.stringify(state),

        // Cargar el estado directamente desde JSON sin descompresión
        deserialize: (str) => {
          try {
            return JSON.parse(str); // Convertir la cadena JSON de vuelta a objeto
          } catch (e) {
            console.error("Error al cargar el estado:", e);
            return {}; // Si hay un error, retornar un estado vacío
          }
        },
      },
    ),
  ),
);
