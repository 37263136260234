import { updateCompany } from "@/api/company/company-services";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

import { zodResolver } from "@hookform/resolvers/zod";

import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const formSchema = z.object({
  alias: z.string().min(4, { message: "Alias is required" }),
  name: z.string().min(4, { message: "Nombre is required" }),
  primaryColor: z.string(),
  secondaryColor: z.string(),
  logo: z.string(),
  template: z.string(),
});

const CreateCompanyForm = () => {
  const { t } = useTranslation();
  const companyData = useStore((state) => state.companyData);
  const [primaryColor, setPrimaryColor] = useState(companyData.primaryColor);
  const [secondaryColor, setSecondaryColor] = useState(
    companyData.secondaryColor,
  );
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      alias: companyData.alias || "",
      name: companyData.name || "",
      primaryColor: companyData.primaryColor || "#000000",
      secondaryColor: companyData.secondaryColor || "#000000",
      logo: companyData.logo || "",
      template: companyData.template || "",
    },
  });

  const onSubmit = (values) => {
    setLoading(true);
    try {
      values.primaryColor = primaryColor;
      values.secondaryColor = secondaryColor;
      updateCompany(values);
    } catch (error) {
      console.error("Error in company updated", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePrimaryColor = (color, event, field) => {
    setPrimaryColor(color.hex);
    field.value = color.hex;
  };
  const handleChangeSecondaryColor = (color, event, field) => {
    setSecondaryColor(color.hex);
    field.value = color.hex;
  };

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.company.companyName")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("forms.company.companyName")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="alias"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.company.alias")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("forms.company.alias")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="primaryColor"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.company.primaryColor")}</FormLabel>
                  <FormControl>
                    <div className="w-full max-w-full">
                      <Controller
                        name="primaryColor"
                        control={form.control}
                        render={({ field }) => (
                          <SketchPicker
                            color={primaryColor}
                            onChange={(color, event) =>
                              handleChangePrimaryColor(color, event, field)
                            }
                            disableAlpha={true}
                            width="40%"
                          />
                        )}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="secondaryColor"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.company.secondaryColor")}</FormLabel>
                  <FormControl>
                    <div className="w-full max-w-full">
                      <Controller
                        name="secondaryColor"
                        control={form.control}
                        render={({ field }) => (
                          <SketchPicker
                            color={secondaryColor}
                            onChange={(color, event) =>
                              handleChangeSecondaryColor(color, event, field)
                            }
                            disableAlpha={true}
                            width="40%"
                          />
                        )}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/*  */}
            <div className="grid w-full max-w-sm items-center gap-1.5 mb-4">
              <FormLabel htmlFor="logo">{t("forms.company.logo")}</FormLabel>
              <Input id="logo" type="file" />
            </div>
            {/*  */}

            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.saveChanges")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};
export default CreateCompanyForm;
