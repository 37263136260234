import { getLanguages } from "@/api/languages/languages-services";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  title: z.string().min(1, { message: "This field is required" }),
  file: z.instanceof(File).refine((file) => file.type === "application/pdf", {
    message: "Only PDF files are allowed",
  }),
});

const AddInterestHomeFileForm = ({ onClose }) => {
  const uploadHomeFile = useStore((state) => state.uploadHomeFile);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [Languages, setLanguages] = useState([]);
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      file: null,
    },
  });

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages = await getLanguages();
        setLanguages(languages);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    const type = "home";
    const isPublic = true;
    const { title, file } = values;
    try {
      await uploadHomeFile(file, type, title, isPublic, selectedLanguage);
      toast.success(t("alertDialog.toast.fileUploadSuccess"));
      form.reset();
      onClose();
    } catch (error) {
      console.error("Error creating state:", error);
      toast.error(t("alertDialog.toast.fileUploadError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.privacyPoicy.language")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      setSelectedLanguage(value);
                    }}
                    value={selectedLanguage}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleccionar idioma" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Languages.map((language) => (
                        <SelectItem
                          key={language.id}
                          value={language.code.toString()}
                        >
                          {language.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel htmlFor="title">
                    {t("headColumns.interestDocs.title")}
                  </FormLabel>
                  <FormControl>
                    <Input id="title" type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel htmlFor="file" className="mr-2">
                    {t("headColumns.interestDocs.document")}
                  </FormLabel>
                  <FormControl>
                    <input
                      id="file"
                      type="file"
                      onChange={(e) => field.onChange(e.target.files[0])}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.uploadFile")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};

export default AddInterestHomeFileForm;
