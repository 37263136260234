import { useStore } from "@/store";
import { ArrowDown, ArrowUp, Minus } from "lucide-react";
const selectedLanguage = useStore.getState().selectedLanguage;

// FUNCIONES PARA UTILIZAR EL HOOK "useStore"
export const useComplaintsStatuses = () => {
  const states = useStore((state) => state.states);
  const complaintsStates = states?.filter(
    (state) => state.type === "complaint",
  );

  return complaintsStates.map((state) => ({
    value: state.id,
    label:
      state.names.find((name) => name.language === selectedLanguage)?.text ||
      "not this lang",
  }));
};

export const useConsultationsStatuses = () => {
  const states = useStore((state) => state.states);
  const consultationsStates = states?.filter(
    (state) => state.type === "consultation",
  );

  return consultationsStates.map((state) => ({
    value: state.id,
    label:
      state.names.find((name) => name.language === selectedLanguage)?.text ||
      "not this lang",
  }));
};

export const statuses = [
  {
    value: "reception",
    label: "Recepción",
  },
  {
    value: "tracing_admitted",
    label: "Admisión a trámite",
  },
  {
    value: "investigation",
    label: "Investigación",
  },
  {
    value: "resolution",
    label: "Resolución",
  },
  {
    value: "close",
    label: "Cierre",
  },
  {
    value: "removed",
    label: "Eliminado",
  },
  {
    value: "tracing",
    label: "Seguimiento",
  },
];

export const priorities = [
  {
    value: "high",
    label: "High",
    icon: ArrowUp,
  },
  {
    value: "medium",
    label: "Medium",
    icon: Minus,
  },
  {
    value: "low",
    label: "Low",
    icon: ArrowDown,
  },
];
