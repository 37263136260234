import { updateCommunication } from "@/api/edit-comunications/edit-comunications";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Form, FormControl } from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DynamicFormField } from "@/components/client/dynamic-field.jsx";
import { useStore } from "@/store";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Toaster, toast } from "sonner";

export function EditDetailsForm({ fetchData }) {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const getStates = useStore((state) => state.getStates);
  const getTags = useStore((state) => state.getTags);
  const { states, tags, currentCommunication } = useStore((state) => ({
    states: state.states,
    tags: state.tags,
    currentCommunication: state.currentCommunication,
  }));

  const [formData, setFormData] = useState({
    state_id: "",
    risk: "medium",
    tags: [],
  });

  const [tagsLoading, setTagsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const form = useForm({
    defaultValues: formData,
  });

  useEffect(() => {
    if (currentCommunication) {
      const initialData = {
        state_id: currentCommunication.state?.id || "",
        risk: currentCommunication.risk || "medium",
        tags: currentCommunication.tags?.map((tag) => tag.id) || [],
      };

      if (currentCommunication.form?.questions) {
        currentCommunication.form.questions
          .filter((question) => question.visibility?.list === true)
          .forEach((question) => {
            const existingAnswer = currentCommunication.answers?.find(
              (answer) => answer.question.alias === question.alias,
            );
            initialData[question.alias] = existingAnswer?.answerText || "";
          });
      }

      setFormData(initialData);
      form.reset(initialData); // Resetear el formulario con los nuevos valores
    }
  }, [currentCommunication, form]);
  useEffect(() => {
    setTagsLoading(true);
    getTags()
        .then(() => setTagsLoading(false))
        .catch(() => setTagsLoading(false));
  }, [getTags]);

  useEffect(() => {
    getStates();
  }, []);

  const listQuestions = currentCommunication?.form?.questions.filter(
      (question) => question.visibility?.list === true,
  );

  useEffect(() => {
    if (listQuestions?.length > 0) {
      listQuestions.forEach((question) => {
        const existingAnswer = currentCommunication.answers?.find(
            (answer) => answer.question.alias === question.alias,
        );
        form.register(question.alias, {
          required: question.required,
          value: existingAnswer?.answerText || "",
        });
      });
    }
  }, [form, listQuestions, currentCommunication?.answers]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    form.setValue(field, value);
  };

  const handleSave = () => {
    setIsSaving(true);

    const formValues = form.getValues();

    const updatedFormData = {
      ...formValues,
    };

    updateCommunication(currentCommunication?.id, updatedFormData)
        .then(() => {
          toast.success(t("alertDialog.toast.updatedSuccess"));
          fetchData();
        })
        .catch((error) => {
          toast.error(t("alertDialog.toast.updatedError"));
          console.error(error);
        })
        .finally(() => {
          setIsSaving(false);
        });
  };

  return (
      <>
        <div className="border border-dashed rounded-lg p-6 border-primary">
          <Toaster />
          <div>
            <span>{t("edit.editForm.identificationNumber")} </span>
            <span>{currentCommunication?.identificationNumber}</span>
          </div>
          <div>
            <span>{t("edit.editForm.date")}: </span>
            <span>
            {currentCommunication?.date &&
                format(
                    new Date(currentCommunication?.date || ""),
                    "dd '-' MM '-' yyyy HH:mm:ss",
                    {
                      locale: es,
                    },
                )}
          </span>
          </div>

          <Form {...form} key={currentCommunication?.id}>
            <div className="w-[50%] text-black-500 mb-4">
              <label htmlFor="state_id">{t("edit.editForm.state")}</label>
              <FormControl>
                <Select
                    onValueChange={(value) => handleChange("state_id", value)}
                    value={formData.state_id}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t("edit.editForm.selectState")} />
                  </SelectTrigger>
                  <SelectContent>
                    {states
                        ?.filter((state) => state.type === currentCommunication?.type)
                        .map((item) => (
                            <SelectItem key={item.id} value={item.id}>
                              {item.names?.find(
                                  (name) => name.language === i18n.language,
                              )?.text || item.names[0].text}
                            </SelectItem>
                        ))}
                  </SelectContent>
                </Select>
              </FormControl>
            </div>

            <div className="w-[50%] text-black-500 mb-4">
              <label htmlFor="risk">{t("edit.editForm.risk")}</label>
              <FormControl>
                <Select
                    onValueChange={(value) => handleChange("risk", value)}
                    value={formData.risk}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={t("edit.editForm.selectRisk")} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="low">
                      {t("edit.editForm.lowRisk")}
                    </SelectItem>
                    <SelectItem value="medium">
                      {t("edit.editForm.mediumRisk")}
                    </SelectItem>
                    <SelectItem value="high">
                      {t("edit.editForm.highRisk")}
                    </SelectItem>
                  </SelectContent>
                </Select>
              </FormControl>
            </div>

            <div className="w-[50%] text-black-500 mb-4">
              <label htmlFor="tags">{t("edit.editForm.tags")}</label>
              <FormControl>
                {tagsLoading ? (
                    <span>...</span>
                ) : (
                    <div className="border rounded p-2">
                      {tags
                          .filter((tag) => tag.type === currentCommunication?.type)
                          .map((tag) => (
                              <div key={tag.id} className="flex items-center mb-2">
                                <input
                                    type="checkbox"
                                    checked={formData.tags.includes(tag.id)}
                                    onChange={() =>
                                        handleChange(
                                            "tags",
                                            formData.tags.includes(tag.id)
                                                ? formData.tags.filter((id) => id !== tag.id)
                                                : [...formData.tags, tag.id],
                                        )
                                    }
                                />
                                <span className="ml-2">{tag.text}</span>
                              </div>
                          ))}
                    </div>
                )}
              </FormControl>
            </div>

            {listQuestions?.length > 0 && (
                <div className="mt-4">
                  {listQuestions.map((question) => (
                      <DynamicFormField
                          key={question.alias}
                          question={question}
                          form={form}
                          disabled={false}
                          defaultValue={form.getValues(question.alias)}
                          onChange={(value) => handleChange(question.alias, value)}
                      />
                  ))}
                </div>
            )}

            <div className="mt-4">
              {isSaving ? (
                  <Loader2 className="animate-spin text-primary" />
              ) : (
                  <Button onClick={handleSave}>{t("edit.editForm.save")}</Button>
              )}
            </div>
          </Form>
        </div>

        <div className="border border-dashed text-black rounded-lg my-6 p-6 border-primary">
          <Card className="mb-4 my-6 text-black">
            <CardHeader>
              <div className="flex p-3">{t("edit.editForm.questions")}:</div>
            </CardHeader>
            <hr />
            <CardContent>
              {currentCommunication?.form?.questions.map((question, index) => {
                const answer = currentCommunication.answers?.find(
                    (ans) => ans.question.id === question.id,
                );

                let answerText = null;

                if (answer) {
                  if (question.type === "select" || question.type === "radio") {
                    // Verificar que `question.options` sea un array antes de buscar la opción seleccionada
                    const selectedOption = Array.isArray(question.options)
                        ? question.options.find((option) => option.value === answer.answerText)
                        : null;

                    if (selectedOption) {
                      const esText = Array.isArray(selectedOption.text)
                          ? selectedOption.text.find((text) => text.language === "es")
                          : null;

                      if (esText) {
                        answerText = esText.text;
                      } else {
                        return null; // Texto en español no encontrado
                      }
                    } else {
                      return null; // Opción seleccionada no encontrada
                    }
                  } else if (question.type === "terms") {
                    answerText = answer.answerText === "1" ? "Sí" : "No"; // Respuesta de tipo términos
                  } else {
                    answerText = answer.answerText; // Texto directo
                  }
                }


                if (answerText) {
                  return (
                      <div key={index}>
                        <p>
                          <strong>
                            {Array.isArray(question?.texts) // Verifica si `texts` es un array
                                ? question.texts.find((text) => text.language === i18n.language)?.text || "Pregunta"
                                : "Pregunta"} {/* Valor predeterminado si `texts` no es un array */}
                          </strong>
                        </p>
                        <p>{answerText}</p>
                      </div>
                  );
                }

                return null;
              })}
            </CardContent>
          </Card>
        </div>
      </>
  );
}
