import { getLanguages } from "@/api/languages/languages-services";

export const appSlice = (set, _get) => ({
  loading: false,
  languages: {},
  setLoading: (loading) => set({ loading }),
  getLanguages: async () => {
    const languages = await getLanguages();
    set({ languages });
  },
});
