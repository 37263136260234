import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { columns } from "@/components/ui/table/columns/privacy-policy";
import { DataTable } from "@/components/ui/table/data-table";
import Loader from "@/components/utils/loader";
import { CreatePolicyForm, UpdatePolicyForm } from "@/pages";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toaster } from "sonner";

const PirvacyPolicyDataTable = () => {
  const { t } = useTranslation();
  const getPrivacyPolicies = useStore((state) => state.getPrivacyPolicies);
  const privacyPolicies = useStore((state) => state.privacyPolicies);
  const updateModal = useStore((state) => state.updateModal);
  const setUpdateModal = useStore((state) => state.setUpdateModal);
  const selectedLanguage = useStore((state) => state.selectedLanguage);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      getPrivacyPolicies();
    } catch (error) {
      console.error("Load policies error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatPolicies = (privacyPolicies) => {
    return (privacyPolicies || []).map((policy) => ({
      id: policy.id,
      title:
        policy.preTexts.find((text) => text.language === selectedLanguage)
          ?.text || "not text in this language",
      policyText:
        policy.postTexts.find((text) => text.language === selectedLanguage)
          ?.text || "not text in this language",
    }));
  };

  const formattedPolicies = formatPolicies(privacyPolicies);

  const handleCloseModal = () => {
    setUpdateModal(false);
  };

  return (
    <section className="w-full">
      <Toaster />
      <section className="w-full flex mb-3">
        <Dialog>
          <DialogTrigger asChild={true}>
            <Button className="mt-4 bg-primary hover:bg-primary-600">
              {t("buttonTexts.addNew")} <Plus className="ml-2" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[625px]">
            <DialogHeader>
              <DialogTitle>
                {t("MainLayout.privacyPolicy.createPrivacyPolicy")}
              </DialogTitle>
            </DialogHeader>
            <CreatePolicyForm />
          </DialogContent>
        </Dialog>
      </section>
      <section className="w-full flex">
        <Dialog
          open={updateModal}
          onOpenChange={(isOpen) => setUpdateModal(isOpen)}
        >
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                {t("MainLayout.privacyPolicy.updatePricavyPolicy")}
              </DialogTitle>
            </DialogHeader>
            <UpdatePolicyForm onClose={handleCloseModal} />
          </DialogContent>
        </Dialog>
      </section>
      {loading ? (
        <div className="flex justify-center h-screen">
          <Loader style={"loader"} />
        </div>
      ) : (
        <DataTable
          data={formattedPolicies}
          columns={columns}
          mainFilterColumn="title"
          textPlaceholder={t("filterTextPlaceholders.privacyPolicy")}
        />
      )}
    </section>
  );
};

export default PirvacyPolicyDataTable;
