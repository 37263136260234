import { Button } from "@/components/ui/button";
import { useStore } from "@/store";
import { t } from "i18next";
import { ArrowUpDown, Pencil, Trash } from "lucide-react";
import { toast } from "sonner";
import { DataTableRowActions } from "../data-table-row-actions.jsx";

const handleDelete = (id) => {
  const deleteTag = useStore.getState().deleteTag;
  deleteTag(id);
  toast.success(t("alertDialog.toast.tagDeletedSuccess"));
};

const handleUpdate = async (id) => {
  const getTagToUpdate = useStore.getState().getTagToUpdate;
  const setUpdateModal = useStore.getState().setUpdateModal;
  try {
    await getTagToUpdate(id);
    setUpdateModal(true);
  } catch (error) {
    console.error("Error al cargar el tag.", error);
  }
};

const tagTypes = (type) => {
  if (type === "complaint") {
    return t("ClientLayout.forms.tags.complaint");
  } else if (type === "consultation") {
    return t("ClientLayout.forms.tags.consultation");
  } else {
    return "";
  }
};

export const columns = [
  {
    accessorKey: "text",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.tags.tagName")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("text")}</div>,
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.tags.type")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="ml-4">{tagTypes(row.getValue("type"))}</div>
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={[
          {
            label: t("buttonTexts.delete"),
            onClick: (row) => handleDelete(row.original.id),
            icon: Trash,
          },
          {
            label: t("buttonTexts.edit"),
            onClick: (row) => handleUpdate(row.original.id),
            icon: Pencil,
          },
        ]}
      />
    ),
  },
];
