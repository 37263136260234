import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { ComplaintFormView } from "@/pages";
import { useStore } from "@/store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"; // Para obtener el idioma actual de la interfaz

const ComplaintsViewsPage = ({ formType }) => {
  const { i18n } = useTranslation(); // Para obtener el idioma actual
  const [selectedView, setSelectedView] = useState("");
  const [selectedLang, setSelectedLang] = useState(i18n.language); // Preseleccionado con el idioma actual
  const getVisibilityOptions = useStore((state) => state.getVisibilityOptions);
  const views = useStore((state) => state.views);
  const getLanguageList = useStore((state) => state.getLanguageList);
  const langList = useStore((state) => state.languageList);

  useEffect(() => {
    getVisibilityOptions();
    getLanguageList();
  }, []);

  // Filtrar solo los idiomas que tienen active: true
  const activeLanguages = langList.filter((lang) => lang.active);

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <section className="w-full flex gap-10">
          {/* Select para vistas */}
          <Select onValueChange={setSelectedView} value={selectedView}>
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Selecciona una vista" />
            </SelectTrigger>
            <SelectContent>
              {views.map((view) => (
                <SelectItem key={view.toLowerCase()} value={view.toLowerCase()}>
                  {view}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          {/* Select para idiomas */}
          <Select onValueChange={setSelectedLang} value={selectedLang}>
            <SelectTrigger className="w-[200px]">
              <SelectValue placeholder="Selecciona idioma" />
            </SelectTrigger>
            <SelectContent>
              {activeLanguages.map((lang) => (
                <SelectItem
                  key={lang.code.toLowerCase()}
                  value={lang.code.toLowerCase()}
                >
                  {lang.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </section>

        {/* Mostrar formulario solo si se seleccionó una vista */}
        {selectedView && (
          <ComplaintFormView
            type={selectedView}
            formType={formType}
            selectedLang={selectedLang}
          />
        )}
      </Card>
    </section>
  );
};

export default ComplaintsViewsPage;
