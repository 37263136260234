import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { settingSites } from "@/config/site";

import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";

const SettingPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const activeTab =
    settingSites.settingNav.find((item) =>
      location.pathname.includes(item.href),
    )?.href || settingSites.settingNav[0].href;

  const handleTabChange = (value) => {
    navigate(value);
  };

  return (
    <>
      <div className="mx-auto grid w-full gap-2">
        <h1 className="text-3xl font-semibold">{t("MainLayout.settings")}</h1>
      </div>
      <div className="mx-auto grid w-full items-start gap-6 mt-6">
        <Tabs value={activeTab} onValueChange={handleTabChange}>
          <TabsList className="flex-wrap">
            {settingSites.settingNav.map((item) => {
              const Icon = item.icon;
              return (
                <TabsTrigger key={item.href} value={item.href}>
                  <NavLink
                    to={item.href}
                    className="flex items-center gap-2 mx-1"
                  >
                    {({ isActive }) => (
                      <>
                        <Icon
                          className={`h-5 w-5 ${isActive ? "text-primary" : ""}`}
                        />
                        <span>{t(item.titleKey)}</span>
                      </>
                    )}
                  </NavLink>
                </TabsTrigger>
              );
            })}
          </TabsList>
          {settingSites.settingNav.map((item) => (
            <TabsContent key={item.href} value={item.href} className="mt-5">
              <Outlet />
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default SettingPage;
