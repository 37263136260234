import api from "../api.js";

//GET : LISTAR LOS ESTADOS
export const getStates = async () => {
  try {
    const response = await api.get("/states");
    return response.data;
  } catch (error) {
    console.error("Error al obtener los estados configurables:", error);
    throw error;
  }
};

//GET: OBTENER UN ESTADO ESPECÍFICO POR ID
export const getStateById = async (id) => {
  try {
    const response = await api.get(`/states/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el estado:", error);
    throw error;
  }
};

//POST: CREAR UN ESTADO
export const createState = async (data) => {
  try {
    const response = await api.post("/states", data);
    return response.data;
  } catch (error) {
    console.error("Error al crear el estado:", error);
    throw error;
  }
};

//DELETE: ELIMINAR UN ESTADO
export const deleteState = async (id) => {
  try {
    const response = await api.delete(`/states/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el estado:", error);
    throw error;
  }
};

//PUT: ACTUALIZAR UN ESTADO
export const updateState = async (id, data) => {
  try {
    const response = await api.put(`/states/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar el estado:", error);
    throw error;
  }
};

export default {
  getStates,
  createState,
  deleteState,
  getStateById,
  updateState,
};
