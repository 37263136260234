import React from "react";
import {useTranslation} from "react-i18next";

import {Checkbox} from "@/components/ui/checkbox";
import {
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {Textarea} from "@/components/ui/textarea";
import {cn} from "@/lib/utils";
import {NavLink} from "react-router-dom";

const DynamicFormField = ({question, form, disabled, view}) => {
    const {t, i18n} = useTranslation();
    const filesRef = form.register("files");

    const findLanguageEntry = (entries) =>
        Array.isArray(entries) // Verifica que `entries` sea un array
            ? entries.find((e) => e.language === i18n.language) // Busca la entrada en el idioma seleccionado
            : null; // Retorna null si `entries` no es un array

    const getLocalizedText = (field) => findLanguageEntry(question[field])?.text;

    const text = question.label || getLocalizedText("texts") || question.alias;
    const placeholder = getLocalizedText("placeholder") || "";
    const description = getLocalizedText("description") || "";

    const getOptionText = (option) =>
        Array.isArray(option?.text)
            ? option.text.find((t) => t.language === i18n.language)?.text || ""
            : "";

    const options =
        question.options?.map((option) => ({
            value: option.value,
            text: getOptionText(option),
        })) ?? [];

    switch (question.type) {
        case "password":
            return (
                <div
                    className="flex flex-col md:flex-row w-full gap-4 md:gap-6"
                    data-type="password"
                >
                    <FormField
                        key={question.id}
                        control={form.control}
                        name={question.alias}
                        disabled={disabled}
                        required={question.required || false}
                        render={({field}) => (
                            <FormItem className="w-fit">
                                <FormLabel>
                                    {text}
                                    {question.required && (
                                        <span className="text-red-500"> *</span>
                                    )}
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        {...field}
                                        type={view === "summary" ? "text" : "password"}
                                        placeholder={placeholder}
                                    />
                                </FormControl>
                                <FormDescription>{description}</FormDescription>
                                <FormMessage/>
                            </FormItem>
                        )}
                    />
                </div>
            );
        case "textarea":
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    disabled={disabled}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem className="w-full" data-type="textarea">
                            <FormLabel>
                                {text}
                                {question.required && <span className="text-red-500"> *</span>}
                            </FormLabel>
                            <FormControl>
                                <Textarea {...field} placeholder={placeholder}/>
                            </FormControl>
                            <FormDescription>{description}</FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            );
        case "terms":
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    disabled={disabled}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem
                            className="flex flex-col md:flex-row gap-3 md:items-center w-full space-y-0"
                            data-type="terms"
                        >
                            <FormControl>
                                <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onCheckedChange={field.onChange}
                                />
                            </FormControl>
                            <FormLabel>
                                <NavLink
                                    to="/terms"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="terms"
                                >
                                    {text}
                                    {question.required && (
                                        <span className="text-red-500"> *</span>
                                    )}
                                </NavLink>
                            </FormLabel>
                        </FormItem>
                    )}
                />
            );
        case "file":
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    disabled={disabled}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem className="w-fit" data-type="file">
                            <FormLabel>
                                {text}
                                {question.required && <span className="text-red-500"> *</span>}
                            </FormLabel>
                            <FormControl>
                                <Input
                                    {...filesRef}
                                    type="file"
                                    multiple={true}
                                    placeholder={placeholder}
                                    onChange={(e) => {
                                        field.onChange(e.target?.files?.[0] ?? undefined);
                                    }}
                                />
                            </FormControl>
                            <FormDescription>{description}</FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            );
        case "select":
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem className="w-fit" data-type="select">
                            <FormLabel>
                                {text}
                                {question.required && <span className="text-red-500"> *</span>}
                            </FormLabel>
                            <Select
                                onValueChange={field.onChange}
                                value={field.value || question.defaultValue || ""}
                            >
                                <FormControl>
                                    <SelectTrigger disabled={disabled}>
                                        <SelectValue placeholder={placeholder}/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {options.map((option, index) => (
                                        <SelectItem key={`option-${index}`} value={option.value}>
                                            {option.text}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <FormDescription>{description}</FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            );
        case "simpleSelect":
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem className="w-fit" data-type="simple-select">
                            <FormLabel>
                                {text}
                                {question.required && <span className="text-red-500"> *</span>}
                            </FormLabel>
                            <Select
                                onValueChange={field.onChange}
                                value={field.value || question.defaultValue || ""}
                            >
                                <FormControl>
                                    <SelectTrigger disabled={disabled}>
                                        <SelectValue placeholder={placeholder}/>
                                    </SelectTrigger>
                                </FormControl>
                                <SelectContent>
                                    {question.options.map((option, index) => (
                                        <SelectItem key={`simple-option-${index}`} value={option.value}>
                                            {option.text}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                            <FormDescription>{description}</FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            );

        case "radio":
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem className="space-y-4 w-full" data-type="radio">
                            <FormLabel>
                                {text}
                                {question.required && <span className="text-red-500"> *</span>}
                            </FormLabel>
                            <FormControl>
                                <RadioGroup
                                    disabled={disabled}
                                    onValueChange={field.onChange}
                                    defaultValue={field.value}
                                    className={cn(
                                        "flex space-x-4",
                                        options.length > 2 && "flex-col space-x-0 space-y-3",
                                    )}
                                >
                                    {options.map((option, index) => (
                                        <FormItem
                                            key={`option-${index}`}
                                            className="flex items-center space-x-2 space-y-0"
                                        >
                                            <FormControl>
                                                <RadioGroupItem value={option.value}/>
                                            </FormControl>
                                            <FormLabel className="normal-case">
                                                {option.text}
                                            </FormLabel>
                                        </FormItem>
                                    ))}
                                </RadioGroup>
                            </FormControl>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            );
        default:
            return (
                <FormField
                    key={question.id}
                    control={form.control}
                    name={question.alias}
                    disabled={disabled}
                    required={question.required || false}
                    render={({field}) => (
                        <FormItem className="w-fit" data-type="default">
                            <FormLabel>
                                {text}
                                {question.required && <span className="text-red-500"> *</span>}
                            </FormLabel>
                            <FormControl>
                                <Input {...field} placeholder={placeholder} type="text"/>
                            </FormControl>
                            <FormDescription>{description}</FormDescription>
                            <FormMessage/>
                        </FormItem>
                    )}
                />
            );
    }
};

export {DynamicFormField};
