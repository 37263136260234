import { login } from "@/api/login/login-services";
import LangSelect from "@/components/lang-select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "User is required" })
    .email({ message: "Invalid email address" }),
  password: z
    .string({
      required_error: "Please enter your password",
    })
    .min(4, { message: "Password must be at least 4 characters long" }),
});

const LoginPage = () => {
  const setLoggedInEmailUser = useStore((state) => state.setLoggedInEmailUser);
  const companyData = useStore((state) => state.companyData);
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  useEffect(() => {
    if (companyData?.title) {
      console.log("Titulo :" + companyData.title);
      document.title = `${companyData.title}`;
    } else {
      document.title = "Canal Ético";
      console.log("No hay titulo");
    }
  }, [companyData?.name]);
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    setIsSubmitting((prev) => !prev);
    try {
      const loginResponse = await login(data.email, data.password);

      if (loginResponse.success) {
        setLoggedInEmailUser(data.email);
        navigate("/2factor");
      } else {
        throw new Error("Error en el login");
      }
    } catch (error) {
      console.error("Error de inicio de sesión:", error);
      setIsSubmitting(false);
      setLoginError(
        "Error en el inicio de sesión. Por favor, verifica tus credenciales.",
      );
    }
  };

  return (
    <section className="grid grid-cols-12 gap-4 w-full min-h-screen items-center">
      <div className="col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 bg-slate-50 p-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="col-start-1 col-span-12 h-auto flex justify-end">
              <LangSelect />
            </div>
            <h2 className="text-2xl mb-6 text-center font-bold">
              {t("LoginForm.login")}
            </h2>
            {companyData && (
              <h3 className="text-xl mb-4 text-center">
                {companyData.name || "Bienvenido a nuestra aplicación"}
              </h3>
            )}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("LoginForm.email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("LoginForm.inputEmail")}
                      {...field}
                      type="email"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("LoginForm.password")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={t("LoginForm.password")}
                      {...field}
                      type="password"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {loginError && (
              <p className="text-red-500 text-sm mt-2">{loginError}</p>
            )}
            <div className="flex justify-center">
              <button
                disabled={isSubmitting}
                className="bg-primary hover:bg-primary-600 text-white font-bold py-2 px-4 mt-2 rounded focus:outline-none focus:shadow-outline w-1/2"
                type="submit"
              >
                {isSubmitting ? (
                  <Loader style={"buttonLoader"} />
                ) : (
                  t("LoginForm.loginButton")
                )}
              </button>
            </div>
          </form>
        </Form>
      </div>
    </section>
  );
};

export default LoginPage;
