import { getConfigurableTexts } from "@/api/client/configurable-texts";
import { getCompany } from "@/api/company/company-services";

export const companySlice = (set, get) => ({
  companyData: {},
  configurableTexts: {},
  getCompanyData: async () => {
    const companyData = await getCompany();
    set({ companyData });
  },
  getConfigurableTexts: async () => {
    const configurableTexts = await getConfigurableTexts();
    set({ configurableTexts });
  },
});
