import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { siteConfig } from "@/config/site";
import { cn } from "@/lib/utils";

const ClientFooter = () => {
  const { t } = useTranslation();

  return (
    <footer className="p-6 w-full bg-gray-dark h-fit text-sm text-white flex flex-col justify-center gap-6 md:px-10 2xl:items-center 2xl:flex-row 2xl:h-14 2xl:justify-between">
      <div className="flex w-full flex-col gap-6 2xl:flex-row 2xl:justify-between max-w-7xl mx-auto">
        <span>
          {new Date().getFullYear()} &copy; GPartners.{" "}
          {t("ClientLayout.footer.copyright")}
        </span>
        <nav
          className={cn(
            "flex flex-col items-start w-full gap-4 text-white",
            "md:flex-row md:w-fit",
          )}
        >
          {siteConfig.clientFooter.map((item, index) => (
            <NavLink
              key={index}
              to={item.href}
              className="hover:underline underline-offset-2"
            >
              {t(item.titleKey)}
            </NavLink>
          ))}
        </nav>
      </div>
    </footer>
  );
};

export { ClientFooter };
