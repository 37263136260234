import { getLanguages } from "@/api/languages/languages-services";

export const languageListSlice = (set, get) => ({
  languageList: {},

  //PARA MANEJAR EL IDIOMA DE TODA LA APLICACIÓN
  selectedLanguage: (navigator.language || "es").split("-")[0],

  getLanguageList: async () => {
    const languageList = await getLanguages();
    set({ languageList });
  },

  setSelectedLanguage: (value) => {
    set({ selectedLanguage: value });
  },
});
