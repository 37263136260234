import api from "../api.js";

//GET: LISTAR TODOS LOS USURIOS
export const getUsers = async () => {
  try {
    const response = await api.get("/users");
    return response.data;
  } catch (error) {
    console.error("Error al obtener los usuarios:", error);
    throw error;
  }
};

//GET: LISTAR UN USUARIO
export const getUser = async (id) => {
  try {
    const response = await api.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el usuario:", error);
    throw error;
  }
};

//GET: LISTA ROLES
export const getRoles = async () => {
  try {
    const response = await api.get("/roles");
    return response.data;
  } catch (error) {
    console.error("Error al obtener los roles:", error);
    throw error;
  }
};

//POST: CREAR NUEVO USUARIO
export const CreateUser = async (userData) => {
  try {
    const response = await api.post("/users", userData);
    return response.data;
  } catch (error) {
    console.error("Error al crear el usuario:", error);
    throw error;
  }
};

//DELETE: ELIMINAR USUARIO
export const DeleteUser = async (id) => {
  try {
    const response = await api.delete(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el usuario:", error);
    throw error;
  }
};

//UPDATE: ACTUALIZAR USUARIO
export const UpdateUser = async (id, userData) => {
  try {
    const response = await api.put(`/users/${id}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error al modificar el usuario:", error);
    throw error;
  }
};

export const assignCommunications = async (userId, communicationId) => {
  try {
    const payload = { communications: communicationId }; // Payload correcto
    const response = await api.put(`/users/${userId}`, payload); // Llamada al endpoint
    return response.data; // Retornar la respuesta
  } catch (error) {
    console.error(`Error al asignar la comunicación ${communicationId} al usuario ${userId}:`, error);
    throw error; // Relanzar el error para manejarlo externamente
  }
};

export default {
  getUsers,
  getUser,
  getRoles,
  CreateUser,
  DeleteUser,
  UpdateUser,
  assignCommunications
};
