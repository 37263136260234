import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

const Breadcrumbs = ({ items }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const currentPageIndex = items.findIndex(
    (item) => location.pathname === item.href,
  );

  if (currentPageIndex === -1) return null;

  const homeItem = items[0];
  const currentItem = items[currentPageIndex];

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink asChild={true}>
            <NavLink to={homeItem.href}>{t(homeItem.titleKey)}</NavLink>
          </BreadcrumbLink>
        </BreadcrumbItem>

        {currentItem.href !== homeItem.href && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t(currentItem.titleKey)}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

export { Breadcrumbs };
