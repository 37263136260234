import api from "@/api/api";

const getFormByType = async (type) => {
  const response = await api.get(`/forms?filters[type]=eq:${type}`);
  return response.data[0];
};

export const inquiryForm = () => getFormByType("consultation");
export const problemForm = () => getFormByType("complaint");

export default { inquiryForm, problemForm };
