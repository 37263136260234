import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumbs } from "@/components/client/breadcrumbs.jsx";
import { InquiryForm } from "@/components/client/forms/inquiry-form.jsx";

const CreateQueryPage = () => {
    const { t } = useTranslation();

    return (
        <div className="space-y-4 sm:space-y-6 lg:space-y-10">
            <Breadcrumbs items={[{ title: t("breadcrumb.dashboard"), url: "/admin/queries" }]} />
            <h1 className="text-2xl font-bold">{t("MainLayout.createQuery.title")}</h1>
            <InquiryForm context="admin"/>
        </div>
    );
};

export default CreateQueryPage;
