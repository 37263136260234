import api from "../api.js";

//GET COMPANY
export const getCompany = async () => {
  try {
    const response = await api.get("/company");
    const data = response.data;

    // Asegúrate de acceder a la propiedad "company"
    const companyData = data.company || {};

    // DEFAULT VALUES
    const defaultValues = {
      id: 0,
      name: "",
      alias: "gpartners",
      logo: "../assets/logo.png",
      primaryColor: "#000000",
      secondaryColor: "#FFFFFF",
      template: "default",
      title: "",
      altSolveTimeLimit: 120,
      solveTimeLimit: 90,
      dateLimitColors: [60,30]
    };

    const processedData = {
      id: companyData.id ?? defaultValues.id,
      name: companyData.name ?? defaultValues.name,
      alias: companyData.alias ?? defaultValues.alias,
      logo: companyData.logo ?? defaultValues.logo,
      primaryColor: companyData.primaryColor ?? defaultValues.primaryColor,
      secondaryColor:
        companyData.secondaryColor ?? defaultValues.secondaryColor,
      template: companyData.template ?? defaultValues.template,
      title : companyData.title ?? defaultValues.title,
      altSolveTimeLimit: companyData.altSolveTimeLimit ?? defaultValues.altSolveTimeLimit,
      solveTimeLimit:companyData.solveTimeLimit ?? defaultValues.solveTimeLimit,
      dateLimitColors: companyData.dateLimitColors ?? defaultValues.dateLimitColors
    };

    return processedData;
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("Error al obtener la empresa");
  }
};

//update COMPANY
export const updateCompany = async (company) => {
  try {
    const response = await api.post("/company", company);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Error en el login");
  }
};
export const setVisit = async () => {
  try {
    const response = await api.post("/company/visit");
    return response.data;
  } catch (error) {
    throw error.response
        ? error.response.data
        : new Error("Error al registrar la visita");
  }
};

export default {
  getCompany,
  updateCompany,
  setVisit,
};