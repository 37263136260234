import {
  createCommunication,
  deleteCommunication,
  getCommunication,
  getComplaints,
  getInquiries,
  getTags,
} from "@/api/client/communications";

export const communicationSlice = (set, get) => ({
  complaints: {},
  inquiries: {},
  communication: {},
  tags: [],

  getComplaints: async () => {
    const { data } = await getComplaints();
    set({ complaints: data });
  },

  getInquiries: async () => {
    const { data } = await getInquiries();
    set({ inquiries: data });
  },

  getCommunication: async (values) => {
    const { data } = await getCommunication(values);
    set({ communication: data });
    return data;
  },

  createCommunication: async (values) => {
    const { data } = await createCommunication(values);
    set({ communication: data });
    return data;
  },

  deleteCommunication: async (id, type) => {
    await deleteCommunication(id);
    const updateFunction =
      type === "complaint" ? get().getComplaints : get().getInquiries;
    updateFunction();
  },

  getTags: async () => {
    const tags = get().tags;

    if (!tags || tags.length === 0) {
      // Cargar solo si están vacíos
      try {
        const data = await getTags(); // Llama directamente a `getTags`
        console.log("Tags cargados desde API:", data); // Verifica que `data` es correcto
        if (data && Array.isArray(data)) {
          set({ tags: data });
        } else {
          console.warn(
            "Los datos de tags no están en el formato esperado:",
            data,
          );
        }
      } catch (error) {
        console.error("Error al cargar las etiquetas:", error);
      }
    } else {
      console.log("Tags ya cargados, omitiendo llamada adicional.");
    }
  },
});
