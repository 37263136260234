import api from "../api.js";

//GET: Obtener los datos para las gráficas
export const getStatsData = async (dates) => {
  try {
    const response = await api.post("/company/stats", dates);
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos:", error);
    throw error;
  }
};
