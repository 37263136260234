import {DynamicFormField} from "@/components/client/dynamic-field";
import {Button, buttonVariants} from "@/components/ui/button";
import {cn, shouldRenderField} from "@/lib/utils"; // Importación actualizada
import React, {useEffect, useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {toast} from "sonner";

const DynamicForm = ({
                         form,
                         questions,
                         onSubmit,
                         currentTab,
                         totalTabs,
                         onPrevious,
                         onNext,
                         disabled,
                         view,
                         context = "public"
                     }) => {
    const formValues = useWatch({control: form.control});
    const reCaptchaRef = useRef();
    const {t} = useTranslation();
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [globalError, setGlobalError] = useState(null); // Nuevo estado para errores globales

    const validateFields = (validateAll = false) => {
        form.clearErrors();
        const values = form.getValues();
        let isValid = true;
        const newErrors = {};

        const passwordQuestions = questions.filter((q) => q.type === "password");
        const lastPasswords = passwordQuestions.slice(-2);

        questions
            .filter((question) => validateAll || question.screen === currentTab)
            .filter(
                (question) =>
                    shouldRenderField(question, values, questions) &&
                    !(context === "admin" && question.type === "terms") // Ignorar preguntas tipo "terms" en admin
            )
            .forEach((question) => {
                const value = values[question.alias] || "";
                newErrors[question.alias] = "";

                if (
                    question.required &&
                    (!value || (typeof value === "string" && value.trim() === ""))
                ) {
                    newErrors[question.alias] = "Este campo es obligatorio";
                    isValid = false;
                } else if (
                    question.type === "email" &&
                    value &&
                    !/^\S+@\S+\.\S+$/.test(value)
                ) {
                    newErrors[question.alias] = "Introduce un email válido";
                    isValid = false;
                } else if (question.type === "password") {
                    if (question.required && (!value || value.length < 6)) {
                        newErrors[question.alias] =
                            "La contraseña debe tener al menos 6 caracteres";
                        isValid = false;
                    }
                }

                if (newErrors[question.alias]) {
                    console.log(
                        `Validation failed for ${question.alias}:`,
                        newErrors[question.alias],
                    );
                }
            });

        if (lastPasswords.length === 2) {
            const [firstPassword, confirmPassword] = lastPasswords.map(
                (q) => values[q.alias],
            );
            if (
                firstPassword &&
                confirmPassword &&
                firstPassword !== confirmPassword
            ) {
                newErrors[lastPasswords[1].alias] = "Las contraseñas no coinciden";
                isValid = false;
            }
        }

        Object.entries(newErrors).forEach(([field, errorMessage]) => {
            if (errorMessage) {
                form.setError(field, {type: "manual", message: errorMessage});
            }
        });

        return isValid;
    };


    const handleSubmit = async (values, event) => {
        event?.preventDefault();
        console.log("Attempting to submit form");

        setErrors({});
        setGlobalError(null); // Resetear error global

        let token = null;

        if (context !== "admin") {
            reCaptchaRef.current.reset();
            try {
                token = await reCaptchaRef.current.executeAsync();
                if (!token) {
                    setGlobalError("Error con el CAPTCHA: No se pudo obtener el token.");
                    return;
                }
            } catch (err) {
                console.error("Error al ejecutar CAPTCHA:", err);
                setGlobalError("Error con el CAPTCHA: " + err.message);
                return;
            }
        }

        const isValid = validateFields(true);

        if (isValid) {
            setIsSubmitting(true);
            try {
                await onSubmit(values, token);
                if (context !== "admin") {
                    reCaptchaRef.current.reset();
                }
            } catch (error) {
                console.error("Error durante el envío del formulario:", error);
                setGlobalError("Error al enviar el formulario: " + (error.message || "Inténtalo de nuevo."));
            } finally {
                setIsSubmitting(false);
            }
        } else {
            if (context !== "admin") {
                reCaptchaRef.current.reset();
            }
            setGlobalError("El formulario contiene errores. Revísalos y vuelve a intentarlo.");
            console.error("Form validation failed, not submitting.");
        }
    };


    const renderQuestionFields = () => {
        const fieldGroups = [];
        let currentGroup = [];

        // Pregunta fija para "admin"
        if (context === "admin") {
            const sourceQuestion = {
                id: "source",
                alias: "source",
                type: "simpleSelect",
                label: t("ClientLayout.forms.source.label"),
                options: [
                    { value: "phone", text: t("ClientLayout.forms.source.options.phone") },
                    { value: "interview", text: t("ClientLayout.forms.source.options.interview") },
                    { value: "email", text: t("ClientLayout.forms.source.options.email") },
                    { value: "letter", text: t("ClientLayout.forms.source.options.letter") },
                ],
                required: true,
            };

            fieldGroups.push(
                <div key={sourceQuestion.id}>
                    <DynamicFormField
                        question={sourceQuestion}
                        form={form}
                        disabled={disabled}
                        view={view}
                    />
                    {errors[sourceQuestion.alias] && (
                        <p className="text-red-500 text-sm mt-1">
                            {errors[sourceQuestion.alias]}
                        </p>
                    )}
                </div>
            );
        }

        // Resto de las preguntas dinámicas
        questions
            .filter((question) => question.active)
            .filter(
                (question) =>
                    (!question.visibility ||
                        (question.visibility[view] !== false &&
                            shouldRenderField(question, formValues, questions))) &&
                    !(context === "admin" && question.type === "terms")
            )
            .sort((a, b) => a.position - b.position)
            .forEach((question) => {
                const field = (
                    <div key={question.id}>
                        <DynamicFormField
                            question={question}
                            form={form}
                            disabled={disabled}
                            view={view}
                        />
                        {errors[question.alias] && (
                            <p className="text-red-500 text-sm mt-1">
                                {errors[question.alias]}
                            </p>
                        )}
                    </div>
                );

                if (
                    ["input", "email", "select", "file"].includes(
                        question.type.toLowerCase()
                    )
                ) {
                    if (currentGroup.length < 2) {
                        currentGroup.push(field);
                    } else {
                        fieldGroups.push(
                            <div
                                key={`input-group-${fieldGroups.length}`}
                                className="flex flex-col w-full gap-4 md:gap-6"
                            >
                                {currentGroup}
                            </div>
                        );
                        currentGroup = [field];
                    }
                } else {
                    if (currentGroup.length > 0) {
                        fieldGroups.push(
                            <div
                                key={`input-group-${fieldGroups.length}`}
                                className="flex flex-col w-full gap-4 md:gap-6"
                            >
                                {currentGroup}
                            </div>
                        );
                        currentGroup = [];
                    }
                    fieldGroups.push(field);
                }
            });

        if (currentGroup.length > 0) {
            fieldGroups.push(
                <div
                    key={`input-group-${fieldGroups.length}`}
                    className="flex flex-col w-full gap-4 md:gap-6"
                >
                    {currentGroup}
                </div>
            );
        }

        return fieldGroups;
    };


    const renderNavigationButtons = () => {
        const commonButtons = (
            <div className="flex gap-3">
                <NavLink to="/" className={buttonVariants({variant: "secondary"})}>
                    {t("ClientLayout.forms.cancel")}
                </NavLink>
                <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => {
                        try {
                            const submitHandler = form.handleSubmit(handleSubmit);
                            submitHandler();
                        } catch (error) {
                            console.error("Error calling form.handleSubmit:", error);
                        }
                    }}
                >
                    {isSubmitting ? (
                        <div className="loader"/>
                    ) : (
                        t("ClientLayout.forms.submit")
                    )}
                </Button>
            </div>
        );
        if (!totalTabs) return commonButtons;

        return (
            <div
                className={cn(
                    "flex w-full gap-3",
                    currentTab === 1 ? "justify-end" : "justify-between",
                )}
            >
                {currentTab > 1 && (
                    <Button type="button" variant="secondary" onClick={onPrevious}>
                        {t("ClientLayout.forms.previous")}
                    </Button>
                )}
                {currentTab < totalTabs && (
                    <Button type="button" onClick={onNext}>
                        {t("ClientLayout.forms.next")}
                    </Button>
                )}
                {currentTab === totalTabs && commonButtons}
            </div>
        );
    };

    return (
        <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="flex flex-col items-end"
        >
            <div className="w-full space-y-4 md:space-y-5 mb-4 md:mb-5">
                {renderQuestionFields()}
            </div>

            {/* Mostrar el error global debajo de las preguntas */}
            {globalError && (
                <div className="w-full mb-4 text-red-500 bg-red-100 p-3 rounded">
                    {globalError}
                </div>
            )}

            {!disabled && (
                <>
                    {renderNavigationButtons()}
                    {context !== "admin" && (
                        <ReCAPTCHA
                            ref={reCaptchaRef}
                            size="invisible"
                            sitekey={import.meta.env.VITE_GOOGLE_CAPTCHA_SITE_KEY}
                        />
                    )}
                </>
            )}
        </form>
    );

};

export {DynamicForm};
