import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Toaster, toast } from "sonner";

import { z } from "zod";

const formSchema = z
  .object({
    name: z.string().min(1, { message: "Name is required" }),
    lastName: z.string().min(0, { message: "Last name is required" }),
    password: z
      .string()
      .min(8, { message: "Password must be at least 8 characters" })
      .optional()
      .or(z.literal("")),
    confirmPassword: z.string().optional().or(z.literal("")),
    email: z.string().email({ message: "Invalid email address" }),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

const MyDataForm = () => {
  const { t } = useTranslation();
  const loggedInUser = useStore((state) => state.loggedInUser);
  const updateLoggedInUser = useStore((state) => state.updateLoggedInUser);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      email: "",
    },
  });

  useEffect(() => {
    if (loggedInUser) {
      form.reset({
        name: loggedInUser.name || "",
        lastName: loggedInUser.lastName || "",
        password: "",
        confirmPassword: "",
        email: loggedInUser.email || "",
      });
    }
  }, [loggedInUser, form]);

  const onSubmit = async (values) => {
    setLoading(true);
    const { confirmPassword, password, ...dataToSubmit } = values;
    if (password) {
      dataToSubmit.password = password;
    }
    try {
      await updateLoggedInUser(loggedInUser.id, dataToSubmit);
      toast.success(t("alertDialog.toast.userUpdatedSuccess"));
    } catch (error) {
      console.error("Error al actualizar el usuario:", error);
      toast.error(t("alertDialog.toast.userUpdatedError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="w-full mb-4">
        <h1 className="text-3xl font-semibold ">{t("forms.myData.header")}</h1>
      </section>
      <section className="w-full flex justify-center">
        <Toaster />
        <Card className="p-6 w-3/4 bg-slate-50">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <h1 className="text-2xl font-light mb-4">
                {t("forms.myData.title")}
              </h1>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel>{t("forms.myData.name")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("forms.myData.name")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel>{t("forms.myData.lastname")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("forms.myData.lastname")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel>{t("forms.myData.email")}</FormLabel>
                    <FormControl>
                      <Input placeholder={t("forms.myData.email")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel>{t("forms.myData.password")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("forms.myData.password")}
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="confirmPassword"
                render={({ field }) => (
                  <FormItem className="mb-4">
                    <FormLabel>{t("forms.myData.confirmPassword")}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder={t("forms.myData.confirmPassword")}
                        type="password"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="mt-4 bg-primary hover:bg-primary-600"
              >
                {loading ? (
                  <Loader style={"buttonLoader"} />
                ) : (
                  t("buttonTexts.saveChanges")
                )}
              </Button>
            </form>
          </Form>
        </Card>
      </section>
    </>
  );
};

export default MyDataForm;
