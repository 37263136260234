import * as React from "react";

import { cn } from "@/lib/utils";

const Card = React.forwardRef(({ className, style, id, ...props }, ref) => {
    return (
        <div
            ref={ref}
            id={id}
            className={cn(
                "rounded-lg border border-slate-200 bg-white text-black shadow-sm dark:border-slate-800 dark:bg-slate-950",
                className,
            )}
            style={style}
            {...props}
        />
    );
});
Card.displayName = "Card";



const CardHeader = React.forwardRef(({ className, style, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("flex flex-col space-y-1.5 p-6", className)}
        style={style} // Propagar estilos aquí
        {...props}
    />
));
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef(({ className, style, ...props }, ref) => (
    <div
        ref={ref}
        className={cn(
            "text-2xl font-semibold leading-none tracking-tight",
            className,
        )}
        style={style} // Propagar estilos aquí
        {...props}
    />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef(({ className, style, ...props }, ref) => (
    <div ref={ref} className={cn("text-sm", className)} style={style} {...props} />
));
CardDescription.displayName = "CardDescription";

const CardContent = React.forwardRef(({ className, style, ...props }, ref) => (
    <div ref={ref} className={cn("p-6 pt-0", className)} style={style} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef(({ className, style, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("flex items-center p-6 pt-0", className)}
        style={style} // Propagar estilos aquí
        {...props}
    />
));
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardHeader,
  CardFooter,
  CardTitle,
  CardDescription,
  CardContent,
};
