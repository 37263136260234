import api from "../api.js";

//GET: LISTAR LAS COMUNICACIONES
export const getCommunications = async () => {
    try {
        const response = await api.get("/communications");
        return response.data;
    } catch (error) {
        console.error("Error al obtener las comunicaciones:", error);
        throw error;
    }
};

//GET: LISTAR 1 FORMULARIO DE DENUNCIAS CON SUS PREGUNTAS
export const getComplaintForm = async (id) => {
    try {
        const response = await api.get(`/forms/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener el formulario de denuncias:", error);
        throw error;
    }
};

//GET: lISTAR LOS FORMULARIOS
export const getForms = async () => {
    try {
        const response = await api.get("/forms/");
        return response.data;
    } catch (error) {
        console.error("Error al obtener el formulario de denuncias:", error);
        throw error;
    }
};

//GET: LISTAR UNA PREGUNTA CONCRETA DEL FORMULARIO
export const getQuestion = async (id) => {
    try {
        const response = await api.get(`/questions/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error al obtener la pregunta:", error);
        throw error;
    }
};

//GET: lISTAR OPTIONS DE VISIBILITY
export const getVisibilityOptions = async () => {
    try {
        const response = await api.get("/configurable_texts/valid_types");
        return response.data;
    } catch (error) {
        console.error("Error al obtener la pregunta:", error);
        throw error;
    }
};

//GET: LISTAR LAS PREGUNTAS DEL FORMULARIO
export const getComplaintQuestions = async () => {
    try {
        const response = await api.get("/questions");
        return response.data;
    } catch (error) {
        console.error("Error al obtener la pregunta:", error);
        throw error;
    }
};

export const getConfigurableTexts = async () => {
    try {
        const response = await api.get("/configurable_texts");
        return response.data;
    } catch (error) {
        console.error("Error al obtener la pregunta:", error);
        throw error;
    }
};

//POST: CREAR NUEVAS PREGUNTAS EN EL FORMULARIO
export const CreateQuestion = async (questionData, id) => {
    try {
        const dataToSend = {
            form_id: id,
            ...questionData,
        };
        const response = await api.post("/questions", dataToSend);
        return response.data;
    } catch (error) {
        console.error("Error al crear la pregunta:", error);
        throw error;
    }
};

//DELETE: ELIMINAR PREGUNTAS EN EL FORMULARIO
export const DeleteQuestion = async (id) => {
    try {
        const response = await api.delete(`/questions/${id}`);
        return response.data;
    } catch (error) {
        console.error("Error al eliminar la pregunta:", error);
        throw error;
    }
};

//PUT: ACTUALIZAR PREGUNTAS EN EL FORMULARIO
export const UpdatetQuestion = async (id, questionData, formId) => {
    try {
        const dataToSend = {
            form_id: formId,
            ...questionData,
        };
        const response = await api.put(`/questions/${id}`, dataToSend);
        return response.data;
    } catch (error) {
        console.error("Error al modificar la pregunta:", error);
        throw error;
    }
};

//PUT: ACTUALIZAR PREGUNTAS EN EL FORMULARIO EN MASA
export const updateQuestionsBulk = async (dataToSend, screen) => {
    try {
        const response = await api.put("/questions/bulk-update?screen=" + screen, dataToSend);
        return response.data;
    } catch (error) {
        console.error("Error al modificar la pregunta:", error);
        throw error;
    }
};

export const updateConfigurableTexts = async (id, dataToSend) => {
    try {
        const response = await api.put(`/configurable_texts/${id}`, dataToSend);
        return response.data;
    } catch (error) {
        console.error("Error al modificar la pregunta:", error);
        throw error;
    }
};

//GET: LISTAR PREGUNTAS ESPECÍFICAS DEL FORMULARIO CON FILTRO
export const getQuestionsByForm = async (formId) => {
    try {
        const response = await api.get(`/questions?filters[form]=eq:${formId}`);
        return response.data;
    } catch (error) {
        console.error(
            "Error al obtener las preguntas del formulario específico:",
            error,
        );
        throw error;
    }
};

export default {
    getCommunications,
    getComplaintForm,
    getVisibilityOptions,
    updateQuestionsBulk,
    getConfigurableTexts,
    CreateQuestion,
    DeleteQuestion,
    UpdatetQuestion,
    getQuestion,
    updateConfigurableTexts,
    getQuestionsByForm,
};
