import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { columns } from "@/components/ui/table/columns/tags";

import { DataTable } from "@/components/ui/table/data-table";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toaster } from "sonner";
import CreateTagForm from "./create-tag-form";
import UpdateTagForm from "./update-tag-form";

const TagsDataTable = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const getTags = useStore((state) => state.getTags);
  const tags = useStore((state) => state.tags);
  const updateModal = useStore((state) => state.updateModal);
  const setUpdateModal = useStore((state) => state.setUpdateModal);

  useEffect(() => {
    try {
      getTags();
    } catch (error) {
      console.error("Load tags error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCloseModal = () => {
    setUpdateModal(false);
  };

  return (
    <section className="w-full">
      <Toaster />
      <section className="w-full flex mb-3">
        <Dialog>
          <DialogTrigger asChild={true}>
            <Button className="mt-4 bg-primary hover:bg-primary-600">
              {t("buttonTexts.addNew")} <Plus className="ml-2" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[625px]">
            <DialogHeader>
              <DialogTitle>{t("MainLayout.tags.createTag")}</DialogTitle>
            </DialogHeader>
            <CreateTagForm />
          </DialogContent>
        </Dialog>
      </section>
      <section className="w-full flex">
        <Dialog
          open={updateModal}
          onOpenChange={(isOpen) => setUpdateModal(isOpen)}
        >
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>{t("MainLayout.tags.updateTag")}</DialogTitle>
            </DialogHeader>
            <UpdateTagForm onClose={handleCloseModal} />
          </DialogContent>
        </Dialog>
      </section>
      {loading ? (
        <div className="flex justify-center">
          <Loader style={"loader"} />
        </div>
      ) : (
        <DataTable
          data={tags}
          columns={columns}
          mainFilterColumn="text"
          textPlaceholder={t("filterTextPlaceholders.tags")}
        />
      )}
    </section>
  );
};

export default TagsDataTable;
