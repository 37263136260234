import { getLanguages } from "@/api/languages/languages-services";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";

import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Controller, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  names: z.record(
    z.string(),
    z.string().min(1, { message: "Este campo es requerido" }),
  ),
  type: z.enum(["complaint", "consultation"], {
    required_error: "Debe seleccionar un tipo",
  }),
  color: z.string().regex(/^#([0-9A-F]{3}){1,2}$/i, {
    message: "Debe ser un color hexadecimal válido",
  }),
  default: z.boolean().default(false),
});

const CreateStateForm = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [Languages, setLanguages] = useState([]);
  const [color, setColor] = useState("#000000");
  const createState = useStore((state) => state.createState);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      names: undefined,
      type: undefined,
      color: "000000",
      default: false,
    },
  });

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const languages = await getLanguages();
        setLanguages(languages);
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };
    fetchLanguages();
  }, []);

  const handleChangeColor = (color, event, field) => {
    setColor(color.hex);
    field.onChange(color.hex);
  };

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      await createState(values);
      toast.success(t("alertDialog.toast.stateCreatedSuccess"));
      form.reset();
    } catch (error) {
      console.error("Error al crear la política de privacidad:", error);
      toast.error(t("alertDialog.toast.stateCreatedError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.privacyPoicy.language")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      setSelectedLanguage(value);
                    }}
                    value={selectedLanguage}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleccionar idioma" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Languages.map((language) => (
                        <SelectItem
                          key={language.id}
                          value={language.code.toString()}
                        >
                          {language.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={`names.${selectedLanguage}`}
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>
                    {t("forms.states.name")} ({selectedLanguage.toUpperCase()})
                  </FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="default"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2 mt-4 mb-4">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      id="default"
                    />
                  </FormControl>
                  <FormLabel
                    htmlFor="default"
                    className="text-sm font-medium leading-none cursor-pointer"
                  >
                    {t("forms.states.default")}
                  </FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>Tipo</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className="flex flex-col space-y-1"
                    >
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="complaint" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {t("forms.states.complaint")}
                        </FormLabel>
                      </FormItem>
                      <FormItem className="flex items-center space-x-3 space-y-0">
                        <FormControl>
                          <RadioGroupItem value="consultation" />
                        </FormControl>
                        <FormLabel className="font-normal">
                          {t("forms.states.consultation")}
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="color"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("forms.states.primaryColor")}</FormLabel>
                  <FormControl>
                    <div className="w-full max-w-full">
                      <Controller
                        name="color"
                        control={form.control}
                        render={({ field }) => (
                          <SketchPicker
                            color={color}
                            onChange={(color, event) =>
                              handleChangeColor(color, event, field)
                            }
                            disableAlpha={true}
                            width="40%"
                          />
                        )}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.create")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};

export default CreateStateForm;
