import {
  createLanguage,
  getLanguages,
  removeLanguage,
  updateLanguage,
} from "@/api/languages/languages-services";
import AlertDialog from "@/components/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { AddLanguageForm } from "@/pages";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";

import { t } from "i18next";
import { Plus, Trash2 } from "lucide-react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  languageList: z
    .array(z.string())
    .refine((value) => value.some((item) => item), {
      message: "You have to select at least one item.",
    }),
});

const AvailableLanguagesConfig = () => {
  const [languages, setLanguages] = useState([]);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [langToDelete, setLangToDelete] = useState(null);
  const [openNewLang, setOpenNewLang] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const fetchLanguages = async () => {
    try {
      const data = await getLanguages();
      setLanguages(data);
    } catch (error) {
      console.error("Error al cargar los idiomas");
    }
  };
  useEffect(() => {
    fetchLanguages();
  }, []);

  const confirmDelete = async () => {
    try {
      await removeLanguage(langToDelete);
      toast.success(t("toast.deletedSuccess"));
      setLanguages(languages.filter((lang) => lang.id !== langToDelete));
      setDeleteDialogOpen(false);
      setLangToDelete(null);
      console.info("Eliminado con éxito");
    } catch (error) {
      console.error("Error al eliminar la pregunta:", error);
      toast.error(t("toast.deletedError"));
    }
  };

  const handleCheck = (language, checked) => {
    const updatedLanguages = languages.map((lang) =>
      lang.code === language.code ? { ...lang, active: checked } : lang,
    );

    setLanguages(updatedLanguages);
    form.setValue(
      "languageList",
      updatedLanguages.filter((lang) => lang.active).map((lang) => lang.code),
    );
    updateLanguage({ ...language, active: checked });
  };

  const handleDeleteLang = (id) => {
    setLangToDelete(id);
    setDeleteDialogOpen(true);
  };

  const onAddSubmit = (values) => {
    createLanguage(values);
    setOpenNewLang(false);
    fetchLanguages();
  };

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <section>
          <Dialog open={openNewLang} onOpenChange={setOpenNewLang}>
            <DialogTrigger asChild={true}>
              <Button className="mt-4 bg-primary hover:bg-primary-600">
                Añadir nuevo idioma <Plus className="ml-2" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <DialogHeader>
                <DialogTitle>Añadir nuevo idioma</DialogTitle>
              </DialogHeader>
              <AddLanguageForm onSubmit={onAddSubmit} />
            </DialogContent>
          </Dialog>
        </section>
        <Form {...form}>
          {/* <form onSubmit={form.handleSubmit(onSubmit)}> */}
          {/* <form> */}
          <FormField
            control={form.control}
            name="languageList"
            render={({ field }) => (
              <FormItem className="flex flex-col items-left space-x-2 space-y-4">
                <div className="mb-4">
                  <FormLabel className="text-base">
                    Listado de idiomas
                  </FormLabel>
                </div>
                {languages.map((language) => (
                  <FormField
                    key={language.code}
                    control={form.control}
                    name="languageList"
                    render={({ field }) => {
                      return (
                        <FormItem
                          key={language.code}
                          className="flex flex-row items-start space-x-3 space-y-0"
                          style={{ alignItems: "center" }}
                        >
                          <FormControl>
                            <Checkbox
                              checked={language.active}
                              onCheckedChange={(checked) =>
                                handleCheck(language, checked)
                              }
                              value={language.code}
                            />
                          </FormControl>
                          <FormLabel className="font-normal sm:w-[150px]">
                            {language.name}
                          </FormLabel>
                          <Button
                            variant="ghost"
                            onClick={() => handleDeleteLang(language.id)}
                          >
                            <Trash2 className="ml-2 sm:w-[18px] sm:h-[18px]" />
                          </Button>
                        </FormItem>
                      );
                    }}
                  />
                ))}
                <FormMessage />
              </FormItem>
            )}
          />
          {/* <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              Actualizar
            </Button> */}
          {/* </form> */}
        </Form>
        <AlertDialog
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          title={t("alertDialog.delete.title")}
          description={t("alertDialog.delete.description")}
          onConfirm={confirmDelete}
        />
      </Card>
    </section>
  );
};
export default AvailableLanguagesConfig;
