import {
  createTag,
  deleteTag,
  getTagById,
  getTags,
  updateTag,
} from "@/api/tags/tags-services";

export const tagsSlice = (set, get) => ({
  tags: [],
  updateModal: false,
  stateToUpdate: {},

  getTags: async () => {
    const data = await getTags();
    set({ tags: data });
  },

  createTag: async (tag) => {
    await createTag(tag);
    get().getTags();
  },

  deleteTag: async (id) => {
    await deleteTag(id);
    get().getTags();
  },

  setUpdateModal: (value) => {
    set((tag) => ({
      updateModal: value,
      tagToUpdate: value ? tag.tagToUpdate : {},
    }));
  },

  getTagToUpdate: async (id) => {
    const data = await getTagById(id);
    set({ tagToUpdate: data });
    return data;
  },

  updateTag: async (id, tag) => {
    await updateTag(id, tag);
    get().getTags();
  },
});
