import { useStore } from "@/store";
import { Navigate, Outlet } from "react-router-dom";

const AdminProtectedRoute = ({ children }) => {
  const loggedInUser = useStore((state) => state.loggedInUser);

  const isAdminRole = () =>
    loggedInUser.roles.some(
      (role) => role === "admin" || role === "superadmin",
    );

  if (!isAdminRole()) {
    return <Navigate to="/" replace={true} />;
  }
  return children ? children : <Outlet />;
};

export default AdminProtectedRoute;
