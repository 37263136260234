import api from "../api.js";

//GET: LISTAR LOS "CONFOGURABLE_TEXTS POR TIPO"
export const getConfTextsByType = async (type) => {
  try {
    const response = await api.get(
      `/configurable_texts?filters[type]=eq:${type}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error al obtener los textos configurables:", error);
    throw error;
  }
};

//GET: LISTAR TIPO "PRIVACY"
export const getPrivacyPolicy = () => getConfTextsByType("Privacy");

//GET: OBTENER UNA POLÍTICA DE PRIVACIDAD ESPECÍFICA POR ID
export const getPrivacyPolicyById = async (id) => {
  try {
    const response = await api.get(`/configurable_texts/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la política de privacidad:", error);
    throw error;
  }
};

//POST : CREAR UNA POLÍTICA DE PRIVACIDAD
export const createPrivacyPolicy = async (data) => {
  try {
    const response = await api.post("/configurable_texts", data);
    return response.data;
  } catch (error) {
    console.error("Error al crear la política de privacidad:", error);
    throw error;
  }
};

//DELETE: ELIMINAR POLÍTICA DE PRIVACIDAD
export const deletePrivacyPolicy = async (id) => {
  try {
    const response = await api.delete(`/configurable_texts/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar la política de privacidad:", error);
    throw error;
  }
};

//PUT: ACTUALIZAR POLÍTICA DE PRIVACIDAD
export const updatePrivacyPolicy = async (id, data) => {
  try {
    const response = await api.put(`/configurable_texts/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar la política de privacidad:", error);
    throw error;
  }
};

export default {
  getConfTextsByType,
  getPrivacyPolicy,
  getPrivacyPolicyById,
  createPrivacyPolicy,
};
