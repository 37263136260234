import Cookies from "js-cookie";
import { useEffect, useState } from "react";

const useCookies = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookies = Cookies.get("cookiesAccepted");
    if (cookies) {
      setCookiesAccepted(true);
    }
  }, []);

  const acceptCookies = () => {
    Cookies.set("cookiesAccepted", "true", { expires: 365 });
    setCookiesAccepted(true);
  };

  return { cookiesAccepted, acceptCookies };
};

export default useCookies;
