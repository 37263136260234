import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Alert } from "@/components/client/alert";
import { SummaryForm } from "@/components/client/forms/summary-form";
import { CloudDownload } from "lucide-react";
import { getCommunicationByToken, downloadPdfByToken } from "@/api/client/communications";
import { getConfigurableTexts } from "@/api/client/configurable-texts"; // Importar la nueva función

const SummaryPage = () => {
  const [communication, setCommunication] = useState(null);
  const [configurableTexts, setConfigurableTexts] = useState([]); // Nuevo estado para los textos configurables
  const [activeTexts, setActiveTexts] = useState({ preText: "", postText: "" });
  const { t, i18n } = useTranslation();
  const { id: accessToken } = useParams(); // Renombrar `id` a `accessToken`
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommunication = async () => {
      try {
        const data = await getCommunicationByToken(accessToken); // Usar `accessToken`
        setCommunication(data);
      } catch (error) {
        console.error("Error al cargar la comunicación:", error);
      }
    };

    fetchCommunication();
  }, [accessToken, navigate]); // Reemplazar `id` por `accessToken`

  useEffect(() => {
    const fetchConfigurableTexts = async () => {
      try {
        const texts = await getConfigurableTexts(); // Obtener textos configurables
        setConfigurableTexts(texts);
      } catch (error) {
        console.error("Error al cargar los textos configurables:", error);
      }
    };

    fetchConfigurableTexts();
  }, []);

  useEffect(() => {
    if (!communication || configurableTexts.length === 0) return;

    const getActiveText = (texts) =>
      texts.find((text) => text.language === i18n.language)?.text || "";

    const homeTexts = configurableTexts.filter(
      (text) =>
        text.type === "Summary" &&
        text.form.id === communication.form.id
    );

    if (homeTexts.length > 0) {
      setActiveTexts({
        preText: getActiveText(homeTexts[0]?.preTexts),
        postText: getActiveText(homeTexts[0]?.postTexts),
      });
    }
  }, [communication, configurableTexts, i18n.language]);

  const handleDownloadPDF = async () => {
    try {
      const pdfData = await downloadPdfByToken(accessToken, i18n.language); // Usar `accessToken`
      const url = window.URL.createObjectURL(
        new Blob([pdfData], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `communication_${accessToken}.pdf`); // Usar `accessToken`
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo PDF:", error);
    }
  };

  if (!communication) {
    return <div>{t("ClientLayout.loading")}</div>;
  }

  return (
    <React.Fragment>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col md:flex-row gap-2">
          <Badge>
            {t("ClientLayout.summary.tracking")}:{" "}
            {communication.identificationNumber}
          </Badge>
          <Badge>{new Date(communication.date).toLocaleString()}</Badge>
        </div>
      </div>
      <Alert type="info">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeTexts?.preText),
          }}
        />
      </Alert>

      <SummaryForm communication={communication} />

      <Alert type="info">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activeTexts?.postText),
          }}
        />
      </Alert>
      <Button
        type="button"
        className="ml-auto w-full md:w-fit flex gap-1.5 items-center"
        icon={CloudDownload}
        onClick={handleDownloadPDF}
      >
        {t("ClientLayout.forms.download")}
      </Button>
    </React.Fragment>
  );
};

export default SummaryPage;
