import api from "@/api/api";

const BASE_URL = "/communications";

export const getCommunicationsByType = async (type) => {
  return await api.get(`${BASE_URL}?filters[type]=eq:${type}`);
};

export const getComplaints = () => {
  return getCommunicationsByType("complaint").then((response) => {
    return response;
  });
};
export const getInquiries = () => getCommunicationsByType("consultation");

export const getCommunication = async (data) => {
  return await api.post(`${BASE_URL}/view`, data);
};

export const createCommunication = async (data) => {
  return await api.post(BASE_URL, data);
};

export const deleteCommunication = async (id) => {
  return await api.delete(`${BASE_URL}/${id}`);
};

//POST: SUBIDA DE FICHEROS
export const uploadFile = async (
  file,
  communicationId = undefined,
  isPublic = true,
  type = "default",
  title = "",
  questionId = "",
  creating = ""
) => {
  const formData = new FormData();
  formData.append("file", file);

  formData.append("communication_id", communicationId);
  formData.append("public", isPublic);
  formData.append("type", type);
  formData.append("title", title);
  formData.append("creating",creating);
  try {
    const response = await api.post("/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 201) {
      console.log("Archivo subido exitosamente");
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.error("Error: Entrada inválida");
    } else {
      console.error("Error al subir el archivo:", error);
    }
    throw error;
  }
};

//POST: SUBIDA DE FICHEROS PARA "HOME"
export const uploadHomeFile = async (file, type, title, isPublic, language) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("type", type);
  formData.append("title", title);
  formData.append("public", isPublic);
  formData.append("language", language);

  try {
    const response = await api.post("/files", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (response.status === 201) {
      console.log("Archivo subido exitosamente");
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      console.error("Error: Entrada inválida");
    } else {
      console.error("Error al subir el archivo:", error);
    }
    throw error;
  }
};

//GET: lISTAR TODOS LOS FICHEROS
export const getFiles = async () => {
  try {
    const response = await api.get("/files");
    return response.data;
  } catch (error) {
    console.error("Error al obtener los archivos:", error);
    throw error;
  }
};

// Bloquear una comunicación
export const blockCommunication = async (id) => {
  try {
    const response = await api.post(`/communications/${id}/block`);
    console.log(`Comunicación ${id} bloqueada con éxito.`);
    return response.data;
  } catch (error) {
    console.error(`Error al bloquear la comunicación ${id}:`, error);
    throw error;
  }
};

// Desbloquear una comunicación
export const unblockCommunication = async (id) => {
  try {
    const response = await api.post(`/communications/${id}/unblock`);
    console.log(`Comunicación ${id} desbloqueada con éxito.`);
    return response.data;
  } catch (error) {
    console.error(`Error al desbloquear la comunicación ${id}:`, error);
    throw error;
  }
};


export const extendCommunicationDeadline = async (id, reason) => {
  const response = await api.post(`/communications/${id}/extend`, {
    reason,
  });
  return response;
};

// Descargar PDF
export const downloadPDF = async (id) => {
  try {
    const response = await api.get(`/communications/${id}/pdf`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error al descargar PDF:", error);
    throw error;
  }
};

// Descargar Excel
export const downloadExcel = async (id) => {
  try {
    const response = await api.get(`/communications/${id}/excel`, {
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error al descargar Excel:", error);
    throw error;
  }
};

export const deleteFile = async (id) => {
  try {
    const response = await api.delete(`/files/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el estado:", error);
    throw error;
  }
};

//GET: DESCARGA DE FICHEROS PDF
export const downloadPdfFile = async (id, langCode) => {
  try {
    const response = await api.get(`/communications/pdf/${id}`, {
      params: { lang: langCode },
      headers: { Accept: "application/pdf" },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error al descargar el archivo:", error);
    throw error;
  }
};

// Obtener etiquetas (tags) comunes
export const getTags = async () => {
  try {
    const response = await api.get("/tags?common=1");
    return response.data;
  } catch (error) {
    console.error("Error al obtener las etiquetas:", error);
    throw error;
  }
};

// Obtener comunicación por accessToken
export const getCommunicationByToken = async (accessToken) => {
  try {
    const response = await api.get(`${BASE_URL}/view/${accessToken}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener la comunicación:", error);
    throw error;
  }
};

// Descargar PDF por accessToken
export const downloadPdfByToken = async (accessToken, langCode) => {
  try {
    const response = await api.get(`${BASE_URL}/pdf/${accessToken}`, {
      params: { lang: langCode },
      headers: { Accept: "application/pdf" },
      responseType: "blob",
    });
    return response.data;
  } catch (error) {
    console.error("Error al descargar el PDF:", error);
    throw error;
  }
};


export default {
  deleteFile,
  downloadPdfFile,
  getComplaints,
  getFiles,
  getInquiries,
  getCommunication,
  createCommunication,
  uploadHomeFile,
  getTags,
  downloadPdfByToken,
  getCommunicationByToken
};
