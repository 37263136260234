import api from "../api.js";

//FUNCIÓN PARA MANEJAR EL LOGIN
export const login = async (email, password) => {
  try {
    const response = await api.post("/users/login", { email, password });
    if (response.status === 200) {
      return { success: true };
    }
  } catch (error) {
    throw error.response ? error.response.data : new Error("Error en el login");
  }
};

//FUNCIÓN PARA MANEJAR EL LOGOUT
export const logout = async (id = 0) => {
  try {
    await api.post("/users/logout", { id });
  } catch (error) {
    console.error("Error durante el logout:", error);
  } finally {
    localStorage.removeItem("token");
    window.location.href = "/";
  }
};

//FUNCIÓN PARA MANEJAR EL 2 FACTOR
export const login2factor = async (email, code) => {
  try {
    const response = await api.post("/users/verify-2fa", { email, code });
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
    }
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Error en el login");
  }
};

//FUNCIÓN PARA COMPROBAR QUE ESTÁ LOGUEADO
export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

export default {
  login,
  logout,
  login2factor,
  isAuthenticated,
};
