import {uploadFile} from "@/api/client/communications";
import {inquiryForm} from "@/api/client/forms";
import {DynamicForm} from "@/components/client/dynamic-form";
import {Form} from "@/components/ui/form";
import {
    generateDefaultValues,
    generateZodSchema,
    processDefaultValues,
} from "@/lib/utils";
import {useStore} from "@/store";
import {zodResolver} from "@hookform/resolvers/zod";
import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {toast} from "sonner";

export function InquiryForm({values, context = "public"}) {
    const {i18n} = useTranslation();
    const [questions, setQuestions] = useState([]);
    const [formSchema, setFormSchema] = useState(null);
    const [defaultValues, setDefaultValues] = useState(null);
    const createCommunication = useStore((state) => state.createCommunication);

    const navigate = useNavigate();
    const {t} = useTranslation();

    const form = useForm({
        defaultValues: defaultValues || {},
    });

    useEffect(() => {
        if (formSchema) {
            form.reset(defaultValues);
            form.control._resolver = zodResolver(formSchema); // Actualiza el resolver
        }
    }, [formSchema, defaultValues, form]);

    const onSubmit = useCallback(
        async (values, _token) => {
            try {
                const {confirmPassword, files, ...filteredValues} = values;
                const language = i18n.language;

                // Crear la comunicación
                const communication = await createCommunication({
                    type: "consultation",
                    risk: "medium",
                    language: language,
                    ...filteredValues,
                });

                // Subir los archivos usando el ID de la comunicación
                if (files && files.length) {
                    const uploadPromises = Array.from(files).map(file =>
                        uploadFile(file, communication.id, 'public', 'communication', null, null, true)
                    );
                    await Promise.all(uploadPromises);
                }

                form.reset(defaultValues);
                if (context === "admin") {
                    navigate(`/admin/queries`);
                } else {
                    navigate(`/summary/${communication.accessToken}`);
                }

            } catch (error) {
                toast.error(t("ClientLayout.forms.inquiry.error"));
            }
        },
        [t, form, uploadFile, createCommunication],
    );

    useEffect(() => {
        const fetchData = async () => {
            const form = await inquiryForm();
            const questions = form.questions;

            // Filtrar las preguntas que tienen question.visibility["form"] = true
            const filteredQuestions = questions?.filter(
                (question) => question.visibility?.form === true,
            );

            setQuestions(filteredQuestions);
            const schema = generateZodSchema(filteredQuestions, t);

            setFormSchema(schema);
            const processedValues = processDefaultValues(values);
            const defaultValues =
                processedValues || generateDefaultValues(filteredQuestions);
            setDefaultValues(defaultValues);
        };
        fetchData();
    }, [t]);

    useEffect(() => {
        if (formSchema && defaultValues) {
            form.reset(defaultValues);
        }
    }, [formSchema, defaultValues]);

    return (
        questions.length > 0 && (
            <div className="border border-dashed rounded-lg p-6 border-primary animate-fade-in">
                <Form {...form}>
                    <DynamicForm
                        questions={questions}
                        form={form}
                        onSubmit={onSubmit}
                        disabled={!!values}
                        view={values ? "summary" : "form"}
                        context={context}
                    />
                </Form>
            </div>
        )
    );
}
