import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import {cn} from "@/lib/utils";

const KPICards = ({data, onCardClick, cardEntity}) => {
    return (
        <div className="grid gap-8 grid-cols-2 md:grid-cols-4 lg:grid-cols-7">
            {data.map((kpi) => (
                <Card
                    key={kpi.id}
                    id={`card-${kpi.id}`}
                    className={cn(kpi.border, "cursor-pointer")}
                    style={{ backgroundColor: kpi.color }}
                    onClick={() => onCardClick(kpi.name)}
                >
                    <CardContent className="flex flex-col justify-between h-full">
                        <CardHeader>
                            <CardDescription
                                className="flex justify-center text-center min-h-[6rem]"
                                style={{
                                    color: kpi.fontColor || 'white', // Aplicar el color aquí
                                }}
                            >
                                {kpi.name}
                            </CardDescription>
                            <CardTitle
                                className="flex text-2xl justify-center mt-auto"
                                style={{
                                    color: kpi.fontColor || 'white', // Aplicar el color aquí
                                }}
                            >
                                {kpi.value}
                            </CardTitle>
                        </CardHeader>
                    </CardContent>
                </Card>
            ))}
        </div>
    );
};


export {KPICards};
