import {
  CreateQuestion,
  DeleteQuestion,
  UpdatetQuestion,
  getForms,
  getQuestion,
  getVisibilityOptions,
} from "@/api/forms-questions/forms-questions-services";

export const formsQuestionsSlice = (set, get) => ({
  complaintQuestions: [],
  consultationQuestions: [],
  views: [],
  updateModal: false,
  questionToUpdateData: {},

  getComplaintQuestions: async () => {
    const allForms = await getForms();
    const complaintForm = allForms.find((form) => form.type === "complaint");
    set({ complaintQuestions: complaintForm.questions });
  },

  getConsultationQuestions: async () => {
    const allForms = await getForms();
    const consultationForm = allForms.find(
      (form) => form.type === "consultation",
    );
    set({ consultationQuestions: consultationForm.questions });
  },

  getVisibilityOptions: async () => {
    const data = await getVisibilityOptions();
    set({ views: data });
    return data;
  },

  createQuestion: async (questionData, formType) => {
    const allForms = await getForms();
    const complaintForm = allForms.find((form) => form.type === formType);
    await CreateQuestion(questionData, complaintForm.id);
    get().getComplaintQuestions();
    get().getConsultationQuestions();
  },

  deleteQuestion: async (id) => {
    await DeleteQuestion(id);
    get().getComplaintQuestions();
    get().getConsultationQuestions();
  },

  setUpdateModal: (value) => {
    set((state) => ({
      updateModal: value,
      questionToUpdateData: value ? state.questionToUpdateData : {},
    }));
  },

  getQuestionToUpdateData: async (id) => {
    const data = await getQuestion(id);
    set({ questionToUpdateData: data });
    return data;
  },

  UpdatetQuestion: async (id, questionData, formType) => {
    const allForms = await getForms();
    const complaintForm = allForms.find((form) => form.type === formType);
    await UpdatetQuestion(id, questionData, complaintForm.id);
    get().getComplaintQuestions();
    get().getConsultationQuestions();
  },
});
