import { cn } from "@/lib/utils";
import { AlertCircle, CheckCircle, TriangleAlert } from "lucide-react";

const alertVariants = {
  error: {
    styles: "border-red-500 text-red-500",
    icon: AlertCircle,
  },
  success: {
    styles: "border-green-700 bg-green-700/40",
    icon: CheckCircle,
    color: "text-green-700",
  },
  warning: {
    styles: "border-yellow-400 bg-yellow-50",
    icon: TriangleAlert,
    color: "text-yellow-400",
  },
  info: {
    styles: "bg-gray/5 border-gray/10",
  },
};

const Alert = ({ children, type, className }) => {
  const { styles, icon: Icon, color } = alertVariants[type];
  const alertClass = cn(
    "flex gap-4 text-sm whitespace-pre-line items-start p-4 border rounded-lg transition-all animate-fade-in",
    styles,
    className,
  );

  return (
    <div className={alertClass}>
      {Icon && <Icon size={21} className={cn("shrink-0", color)} />}
      {children}
    </div>
  );
};

export { Alert };
