import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "es",
    fallbackLng: "es",
    supportedLngs: ["es", "en"],
    backend: {
      //Para el servidor
      loadPath: "/Translations/{{lng}}.json",

      //Para desarrollo
      // loadPath: "./public/Translations/{{lng}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
