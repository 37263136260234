import { useStore } from "@/store";
import DOMPurify from "dompurify";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ProblemPage = () => {
  const { t, i18n } = useTranslation();
  const configurableTexts = useStore((state) => state.configurableTexts);
  const [activeTexts, setActiveTexts] = useState({ preText: "", postText: "" });

  useEffect(() => {
    if (!configurableTexts.length) return;

    const getActiveText = (texts) =>
      texts?.find((text) => text.language === i18n.language)?.text || "";

    // Verificamos si existe homeTexts y si tiene las propiedades preTexts y postTexts
    const homeTexts = configurableTexts.find((text) => text.type === "Terms");

    if (homeTexts) {
      setActiveTexts({
        preText: getActiveText(homeTexts.preTexts),
        postText: getActiveText(homeTexts.postTexts),
      });
    }
  }, [configurableTexts, i18n.language]);

  return (
    <React.Fragment>
      {/* El título ahora es activeTexts.preText, sanitizado */}
      <h1
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            activeTexts?.preText || t("ClientLayout.terms.title"),
          ),
        }}
      />
      {/* El contenido dentro del div será activeTexts.postText, sanitizado */}
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(activeTexts?.postText || ""),
        }}
      />
    </React.Fragment>
  );
};

export default ProblemPage;
