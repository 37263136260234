import { logout } from "@/api/login/login-services";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useStore } from "@/store";
import {
  CircleUserRound,
  EllipsisVertical,
  Globe,
  LogOut,
  User,
} from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const UserNav = () => {
  const loggedInUser = useStore((state) => state.loggedInUser);
  const getLanguages = useStore((state) => state.getLanguages);
  const languages = useStore((state) => state.languages);
  const setSelectedLanguage = useStore((state) => state.setSelectedLanguage);
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  useEffect(() => {
    getLanguages();
  }, []); // Ejecutar solo al montar el componente

  useEffect(() => {
    if (languages.length === 1) {
      const defaultLanguage = languages[0];
      i18n.changeLanguage(defaultLanguage.code);
      setSelectedLanguage(defaultLanguage.code);
    } else if (!i18n.language) {
      i18n.changeLanguage("es");
      setSelectedLanguage("es");
    }
  }, [languages, i18n, setSelectedLanguage]); // Mantener dependencias solo para el cambio de idioma


  const handleLogout = async () => {
    await logout();
    // Add navigation logic here if needed
  };

  return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild={true}>
          <button className="relative whitespace-nowrap focus:outline-none transition-all text-center group flex w-full items-center justify-between rounded-lg p-2 text-sm font-medium text-slate-500 hover:bg-slate-100/50">
          <span className="flex items-center gap-3">
            <Avatar>
              <AvatarImage />
              <AvatarFallback className="rounded-full group-hover:border-primary transition-all bg-white border">
                <CircleUserRound />
              </AvatarFallback>
            </Avatar>
            <span>{loggedInUser.name}</span>
          </span>
            <EllipsisVertical className="h-4 w-4" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>{loggedInUser.email}</DropdownMenuLabel>
          <DropdownMenuGroup>
            <Link to={"/admin/profile"} className="flex">
              <DropdownMenuItem>
                <User className="mr-2 h-4 w-4" />
                <span>{t("MainLayout.userNav.profile")}</span>
              </DropdownMenuItem>
            </Link>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <Globe className="mr-2 h-4 w-4" />
                <span>{t("MainLayout.userNav.language")}</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuPortal>
                <DropdownMenuSubContent>
                  {languages.length &&
                      languages.map((language) => {
                        const active = i18n.language === language.code;

                        return (
                            <DropdownMenuItem
                                key={language.id}
                                className="cursor-pointer"
                                onClick={() => changeLanguage(language.code)}
                                disabled={active}
                            >
                              <span className="w-full">{language.name}</span>
                            </DropdownMenuItem>
                        );
                      })}
                </DropdownMenuSubContent>
              </DropdownMenuPortal>
            </DropdownMenuSub>
          </DropdownMenuGroup>
          <DropdownMenuGroup />
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={handleLogout} className="cursor-pointer">
            <LogOut className="mr-2 h-4 w-4" />
            <span>{t("MainLayout.userNav.logout")}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
  );
};

export { UserNav };
