import { EditDetailsForm } from "@/components/client/forms/edit-details-form";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useStore } from "@/store";
import { useEffect, useState } from "react";

import FileUploadAndComments from "@/components/client/forms/file-upload-and-comments";
import Loader from "@/components/utils/loader";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { ArrowLeft } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useParams } from "react-router-dom";

const EditPage = ({ type }) => {
  const { getCommunicationById, currentCommunication } = useStore((state) => ({
    getCommunicationById: state.getCommunicationById,
    currentCommunication: state.currentCommunication,
  }));
  const { t } = useTranslation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  const tabsList = [
    {
      name: t("edit.editForm.details"),
      id: 0,
    },
    {
      name: t("edit.editForm.publicMessages"),
      id: 1,
    },
    {
      name: t("edit.editForm.privateMessages"),
      id: 2,
    },
    {
      name: t("edit.editForm.log"),
      id: 3,
    },
  ];

  useEffect(() => {
    try {
      getCommunicationById(id);
    } catch (error) {
      console.error("Error con la carga", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleTabChange = (value) => {
    setActiveTab(value);
  };

  if (loading) {
    return (
      <div className="flex justify-center h-screen">
        <Loader style={"loader"} />
      </div>
    );
  }

  return (
    <div className="space-y-4 sm:space-y-6 lg:space-y-10">
      <section className="flex flex-col gap-6">
        <h1 className="flex items-center gap-2">
          <Link
            to={`/admin/${currentCommunication?.type === "complaint" ? "complaints" : "queries"}`}
            className="flex"
          >
            <ArrowLeft className="ml-2 h-6 w-6" />
          </Link>
          {currentCommunication?.type === "complaint"
            ? t("edit.editForm.complaintDetail")
            : t("edit.editForm.querieDetail")}
          : {id}
        </h1>
        <div className="mx-auto grid w-full items-start gap-6 mt-6">
          <Tabs value={activeTab} onValueChange={handleTabChange}>
            <TabsList className="flex-wrap">
              {tabsList.map((item) => {
                return (
                  <TabsTrigger key={item.id} value={item.id}>
                    <NavLink className="flex items-center gap-2 mx-1">
                      {({ isActive }) => <span>{t(item.name)}</span>}
                    </NavLink>
                  </TabsTrigger>
                );
              })}
            </TabsList>
            <TabsContent key={"tab-0"} value={0} className="mt-5">
              <EditDetailsForm fetchData={() => getCommunicationById(id)} />
            </TabsContent>
            <TabsContent key={"tab-1"} value={1} className="mt-5">
              <FileUploadAndComments viewType="public" isAdmin={true} communication={currentCommunication} />
            </TabsContent>
            <TabsContent key={"tab-2"} value={2} className="mt-5">
              <FileUploadAndComments viewType="private" isAdmin={true} communication={currentCommunication} />
            </TabsContent>
            <TabsContent key={"tab-3"} value={3} className="mt-5">
              <div className="border border-dashed rounded-lg my-6 p-6 border-primary">
                {currentCommunication?.logEvents?.length > 0 ? (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="text-left">{t("edit.log.date")}</th>
                        <th className="text-left">{t("edit.log.userName")}</th>
                        <th className="text-left">
                          {t("edit.log.message")}
                        </th>
                        <th className="text-left">{t("edit.log.eventName")}</th>
                        <th className="text-left">{t("edit.log.result")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentCommunication?.logEvents
                        ?.sort(
                          (a, b) =>
                            new Date(b.eventDateTime) -
                            new Date(a.eventDateTime),
                        )
                        .map((log, index) => (
                          <tr key={log.id}>
                            <td className="border-b border-gray-200">
                              {log?.eventDateTime &&
                                format(
                                  new Date(log?.eventDateTime || ""),
                                  "dd '-' MM '-' yyyy HH:mm:ss",
                                  {
                                    locale: es,
                                  },
                                )}
                            </td>
                            <td className="border-b border-gray-200">
                              {log?.user?.name} {log?.user?.lastName}
                            </td>
                            <td className="border-b border-gray-200">
                              {log?.message}
                            </td>
                            <td className="border-b border-gray-200">
                              {log?.eventName}
                            </td>
                            <td className="border-b border-gray-200">
                              {log?.result}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No events logs available.</p>
                )}
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </section>
    </div>
  );
};

export default EditPage;
