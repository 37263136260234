import { Button } from "@/components/ui/button";
import { useStore } from "@/store";
import { t } from "i18next";
import { ArrowUpDown, Trash } from "lucide-react";
import { toast } from "sonner";
import { DataTableRowActions } from "../data-table-row-actions";

const handleDelete = async (id) => {
  try {
    const deleteHomeFile = useStore.getState().deleteHomeFile;
    await deleteHomeFile(id);
    toast.success(t("alertDialog.toast.fileDeleteSuccess"));
  } catch (error) {
    console.error("Error deleting file:", error);
    toast.error(t("alertDialog.toast.fileDeleteError"));
  }
};

export const columns = [
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.interestDocs.title")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("title")}</div>,
  },
  {
    accessorKey: "document",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.interestDocs.document")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("document")}</div>,
  },

  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={[
          {
            label: t("buttonTexts.delete"),
            onClick: (row) => handleDelete(row.original.id),
            icon: Trash,
          },
        ]}
      />
    ),
  },
];
