import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

// Validadores adicionales
const hasLowerCase = (str) => /[a-z]/.test(str);
const hasUpperCase = (str) => /[A-Z]/.test(str);
const hasNumber = (str) => /\d/.test(str);
const hasSpecialCharacter = (str) => /[!@#$%^&*]/.test(str);

// Función principal para generar el esquema Zod
export const generateZodSchema = (questions, t) => {
  // Validación de contraseña
  const passwordValidation = (field) => {
    const message =
      typeof t("ClientLayout.forms.errors.passwordLength") === "string"
        ? t("ClientLayout.forms.errors.passwordLength")
        : "La contraseña debe tener al menos 6 caracteres";
    return field.min(6, message);
  };

  // Mapa de tipos de campo y sus validaciones
  const fieldTypeMap = {
    email: (field) => {
      const message =
        typeof t("ClientLayout.forms.errors.email") === "string"
          ? t("ClientLayout.forms.errors.email")
          : "Introduce un email válido";
      return field.email(message);
    },
    terms: () => {
      const message =
        typeof t("ClientLayout.forms.errors.terms") === "string"
          ? t("ClientLayout.forms.errors.terms")
          : "Debes aceptar los términos";
      return z.boolean().refine((value) => value === true, { message });
    },
    file: () => z.any(),
    password: (field) => passwordValidation(field),
  };

  // Función para crear un campo Zod para cada pregunta
  const createZodField = (question) => {
    const requiredMessage =
      typeof t("ClientLayout.forms.errors.required") === "string"
        ? t("ClientLayout.forms.errors.required")
        : "Este campo es obligatorio";

    let field;

    // Configura el campo según el tipo de pregunta
    if (question.type === "email") {
      const emailMessage =
        typeof t("ClientLayout.forms.errors.email") === "string"
          ? t("ClientLayout.forms.errors.email")
          : "Introduce un email válido";
      field = z.string().email(emailMessage);
      if (!question.required) {
        field = field.optional(); // Aplica `optional()` solo después de la validación de email
      }
    } else if (question.type === "password") {
      field = passwordValidation(z.string());
      if (!question.required) {
        field = field.optional();
      }
    } else if (question.type === "terms") {
      const termsMessage =
        typeof t("ClientLayout.forms.errors.terms") === "string"
          ? t("ClientLayout.forms.errors.terms")
          : "Debes aceptar los términos";
      field = z
        .boolean()
        .refine((value) => value === true, { message: termsMessage });
      if (!question.required) {
        field = field.optional();
      }
    } else if (question.type === "file") {
      field = z.any();
    } else {
      // Si el tipo no es específico, usa `string` o `string` con mínimo si es requerido
      field = question.required
        ? z.string().min(1, requiredMessage)
        : z.string().optional();
    }

    return field;
  };

  // Construcción del esquema a partir de las preguntas
  const schemaObject = questions.reduce((schema, question) => {
    const zodField = createZodField(question);
    schema[question.alias] = question.required ? zodField : zodField.optional();
    if (question.type === "password") {
      schema["confirmPassword"] = z.string().optional();
    }
    return schema;
  }, {});

  // Validaciones adicionales usando `superRefine` para campos dependientes y contraseñas
  return z.object(schemaObject).superRefine((data, ctx) => {
    questions.forEach((question) => {
      if (question.depends) {
        const parentValue = data[question.depends.id];
        const requiredMessage =
          typeof t("ClientLayout.forms.errors.required") === "string"
            ? t("ClientLayout.forms.errors.required")
            : "Este campo es obligatorio";
        if (
          parentValue === question.depends.value &&
          (!data[question.alias] || data[question.alias].length === 0) &&
          question.required
        ) {
          ctx.addIssue({ message: requiredMessage, path: [question.alias] });
        }
      }
    });

    if (
      data.password &&
      data.confirmPassword &&
      data.password !== data.confirmPassword
    ) {
      const mismatchMessage =
        typeof t("ClientLayout.forms.errors.passwordMismatch") === "string"
          ? t("ClientLayout.forms.errors.passwordMismatch")
          : "Las contraseñas no coinciden";
      ctx.addIssue({ message: mismatchMessage, path: ["confirmPassword"] });
    }
  });
};

// Valores predeterminados para cada pregunta
export const generateDefaultValues = (questions) => {
  return questions.reduce((values, question) => {
    values[question.alias] = question.type === "Terms" ? false : "";
    return values;
  }, {});
};

export const processDefaultValues = (values) => {
  if (!values || !values.answers) return null;
  return values.answers.reduce((acc, answer) => {
    const { alias, type } = answer.question;
    let value = answer.answerText;
    if (type === "Terms") value = value === "1";
    acc[alias] = value;
    return acc;
  }, {});
};

// Cálculo de KPIs
export const calculateKpis = (items, cardTypes) => {
  if (!Array.isArray(items)) return [];
  if (!Array.isArray(cardTypes)) return [];

  const cardTypeMap = cardTypes.reduce((acc, cardType) => {
    acc[cardType.id] = {
      name: cardType.name,
      color: cardType.color,
      fontColor: cardType.fontColor || "#FFFFFF", // Color blanco por defecto
    };
    return acc;
  }, {});

  const counts = cardTypes.reduce((acc, cardType) => {
    acc[cardType.id] = { count: 0, ...cardType };
    return acc;
  }, {});

  items.forEach((item) => {
    const es_state_name = item.state?.names?.find(
        (name) => name.language === "es",
    )?.text;
    const matched_card_type = Object.values(cardTypeMap).find(
        (cardType) => cardType.name === es_state_name,
    );
    if (matched_card_type) {
      const card_type_id = Object.keys(cardTypeMap).find(
          (key) => cardTypeMap[key].name === es_state_name,
      );
      if (counts[card_type_id]) {
        counts[card_type_id].count++;
      }
    }
  });

  // Asegúrate de incluir fontColor en el resultado final
  return cardTypes.map((cardType) => ({
    id: cardType.id,
    name: cardTypeMap[cardType.id].name,
    value: counts[cardType.id]?.count?.toString() || "0",
    color: cardTypeMap[cardType.id].color,
    fontColor: cardTypeMap[cardType.id].fontColor,
    border: "#A09D9E",
  }));
};



export const shouldRenderField = (question, formValues, questions) => {
  if (!question.depends) return true;

  const parentQuestion = questions.find((q) =>
    q.options?.some((opt) => opt.id === question.depends.id),
  );
  if (!parentQuestion) return true;

  const dependentValue = formValues[parentQuestion.alias];
  return dependentValue === question.depends.value;
};
