import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { columns } from "@/components/ui/table/columns/state";
import { DataTable } from "@/components/ui/table/data-table";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Toaster } from "sonner";
import CreateStateForm from "./create-state-form";
import UpdateStateForm from "./update-state-form";

const StateDataTable = () => {
  const { t } = useTranslation();
  const getStates = useStore((state) => state.getStates);
  const states = useStore((state) => state.states);
  const updateModal = useStore((state) => state.updateModal);
  const setUpdateModal = useStore((state) => state.setUpdateModal);
  const selectedLanguage = useStore((state) => state.selectedLanguage);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      getStates();
    } catch (error) {
      console.error("Load states error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const formatStates = (states) => {
    return (states || []).map((state) => ({
      id: state.id,
      name:
        state.names.find((name) => name.language === selectedLanguage)?.text ||
        "not text in this language",
      type: state.type.toUpperCase(),
      color: state.color,
      default: state.default ? "Sí" : "No",
    }));
  };

  const formattedStates = formatStates(states);

  const handleCloseModal = () => {
    setUpdateModal(false);
  };
  return (
    <section className="w-full">
      <Toaster />
      <section className="w-full flex mb-3">
        <Dialog>
          <DialogTrigger asChild={true}>
            <Button className="mt-4 bg-primary hover:bg-primary-600">
              {t("buttonTexts.addNew")} <Plus className="ml-2" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>{t("MainLayout.states.createState")}</DialogTitle>
            </DialogHeader>
            <CreateStateForm />
          </DialogContent>
        </Dialog>
      </section>
      <section className="w-full flex">
        <Dialog
          open={updateModal}
          onOpenChange={(isOpen) => setUpdateModal(isOpen)}
        >
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>{t("MainLayout.states.updateState")}</DialogTitle>
            </DialogHeader>
            <UpdateStateForm onClose={handleCloseModal} />
          </DialogContent>
        </Dialog>
      </section>
      {loading ? (
        <div className="flex justify-center h-screen">
          <Loader style={"loader"} />
        </div>
      ) : (
        <DataTable
          data={formattedStates}
          columns={columns}
          mainFilterColumn="name"
          textPlaceholder={t("filterTextPlaceholders.state")}
        />
      )}
    </section>
  );
};

export default StateDataTable;
