import {
  BookOpen,
  Building2,
  Eye,
  FileCheck2,
  Info,
  Landmark,
  Languages,
  Layers,
  Layers2,
  ListTree,
  Settings,
  Tags,
  TrendingUp,
  Users,
  View,
} from "lucide-react";

// const isAdminRole = () => {
//   const loggedInUser = useStore.getState().loggedInUser;
//   return loggedInUser.roles.includes("admin");
// };

export const siteConfig = {
  mainNav: [
    {
      titleKey: "MainLayout.complaints",
      href: "/admin/complaints",
      icon: FileCheck2,
    },
    {
      titleKey: "MainLayout.queries",
      href: "/admin/queries",
      icon: Info,
    },
    {
      titleKey: "MainLayout.reports",
      href: "/admin/stats",
      icon: TrendingUp,
      // disabled: true,
    },
    {
      titleKey: "MainLayout.settings",
      href: "settings",
      icon: Settings,
      adminOnly: true,
    },
  ],
  clientNav: [
    {
      titleKey: "ClientLayout.nav.home",
      href: "/",
    },
    {
      titleKey: "ClientLayout.nav.inform",
      href: "/problem",
    },
    {
      titleKey: "ClientLayout.nav.consult",
      href: "/inquiry",
    },
  ],
  clientFooter: [],
};

export const settingSites = {
  settingNav: [
    {
      titleKey: "MainLayout.company.companyTitle",
      href: "company",
      icon: Building2,
    },
    {
      titleKey: "MainLayout.users.usersTitle",
      href: "users",
      icon: Users,
    },
    {
      titleKey: "MainLayout.languages.languagesTitle",
      href: "languages",
      icon: Languages,
    },
    {
      titleKey: "MainLayout.ComplaintsForm.Title",
      href: "complaints-form-details",
      icon: Layers,
    },
    {
      titleKey: "MainLayout.ComplaintsView.Title",
      href: "complaints-views-page",
      icon: Eye,
    },
    {
      titleKey: "MainLayout.QueriesForm.Title",
      href: "queries-form-details",
      icon: Layers2,
    },
    {
      titleKey: "MainLayout.QueriesView.Title",
      href: "queries-views-page",
      icon: View,
    },
    {
      titleKey: "MainLayout.TagsView.Title",
      href: "tags",
      icon: Tags,
    },
    {
      titleKey: "MainLayout.privacyPolicy.privacyPolicyTitle",
      href: "policy",
      icon: Landmark,
    },
    {
      titleKey: "MainLayout.State.Title",
      href: "state",
      icon: ListTree,
    },
    {
      titleKey: "MainLayout.documentOfInterest.Title",
      href: "interestDocs",
      icon: BookOpen,
    },
  ],
};
