import { zodResolver } from "@hookform/resolvers/zod";
import { Search } from "lucide-react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useStore } from "@/store";

export function TrackForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const getCommunication = useStore((state) => state.getCommunication);

  const formSchema = z.object({
    identificationNumber: z.string().min(1, {
      message: t("ClientLayout.forms.track.errors.number"),
    }),
    password: z.string().min(1, {
      message: t("ClientLayout.forms.track.errors.password"),
    }),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { identificationNumber: "", password: "" },
  });

  const onSubmit = async (values) => {
    try {
      const communication = await getCommunication(values);
      navigate(`/communication/${communication.accessToken}`);
    } catch (error) {
      toast.error(t("ClientLayout.forms.track.errors.credentials"));
    }
  };

  return (
    <div className="border border-dashed rounded-lg p-6 border-primary">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col md:flex-row items-end gap-4 md:gap-5"
        >
          <FormField
            control={form.control}
            name="identificationNumber"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>{t("ClientLayout.forms.track.number")}</FormLabel>
                <FormControl>
                  <Input placeholder="1Z999AA10123456784" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>{t("ClientLayout.forms.track.password")}</FormLabel>
                <FormControl>
                  <Input {...field} type="password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="w-full md:w-fit flex gap-1.5 items-center"
            loading={form.formState.isSubmitting}
            icon={Search}
          >
            {t("ClientLayout.forms.track.button")}
          </Button>
        </form>
      </Form>
    </div>
  );
}
