import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { getCommunicationByToken, downloadPdfFile } from "@/api/client/communications";
import { getConfigurableTexts } from "@/api/client/configurable-texts"; // Importar la nueva función
import { SummaryForm } from "@/components/client/forms/summary-form";
import FileUploadAndComments from "@/components/client/forms/file-upload-and-comments";
import { CloudDownload } from "lucide-react";
import {Alert} from "@/components/client/alert.jsx";
import DOMPurify from "dompurify";

const CommunicationPage = () => {
  const [communication, setCommunication] = useState(null);
  const [configurableTexts, setConfigurableTexts] = useState([]); // Nuevo estado para los textos configurables
  const [activeTexts, setActiveTexts] = useState({ preText: "", postText: "" });

  const { t, i18n } = useTranslation();
  const { id: accessToken } = useParams(); // Interpretar `id` como `accessToken`
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommunication = async () => {
      try {
        const data = await getCommunicationByToken(accessToken); // Obtener datos de la API
        setCommunication(data);
      } catch (error) {
        console.error("Error al cargar la comunicación:", error);
        navigate("/"); // Redirigir si hay un error
      }
    };

    fetchCommunication();
  }, [accessToken, navigate]);

  useEffect(() => {
    const fetchConfigurableTexts = async () => {
      try {
        const texts = await getConfigurableTexts(); // Obtener textos configurables
        setConfigurableTexts(texts);
      } catch (error) {
        console.error("Error al cargar los textos configurables:", error);
      }
    };

    fetchConfigurableTexts();
  }, []);

  useEffect(() => {
    if (!communication || configurableTexts.length === 0) return;

    const getActiveText = (texts) =>
        texts.find((text) => text.language === i18n.language)?.text || "";

    const homeTexts = configurableTexts.find(
        (text) => text.type === "Follow" && text.form.id === communication.form.id
    );

    setActiveTexts({
      preText: getActiveText(homeTexts?.preTexts || []),
      postText: getActiveText(homeTexts?.postTexts || []),
    });
  }, [communication, configurableTexts, i18n.language]);

  const handleDownloadPDF = async () => {
    try {
      const pdfData = await downloadPdfFile(accessToken, i18n.language); // Usar `accessToken`
      const url = window.URL.createObjectURL(
          new Blob([pdfData], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `communication_${accessToken}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  const getStateText = () => {
    if (!communication?.state?.names) {
      return t("ClientLayout.state.unknown");
    }
    const stateText = communication.state?.names.find(
        (item) => item.language === i18n.language
    );
    return stateText?.text || t("ClientLayout.state.unknown");
  };

  const getStateColor = () => {
    return communication?.state?.color || "#cccccc";
  };

  if (!communication) {
    return <div>{t("ClientLayout.loading")}</div>;
  }

  return (
      <React.Fragment>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col md:flex-row gap-2">
            <Badge>
              {t("ClientLayout.summary.tracking")}:{" "}
              {communication.identificationNumber}
            </Badge>
            <Badge>{new Date(communication.date).toLocaleString()}</Badge>
          </div>
        </div>

        <Alert type="info">
          <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(activeTexts?.preText),
              }}
          />
        </Alert>

        <SummaryForm communication={communication} />

        <Alert type="info">
          <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(activeTexts?.postText),
              }}
          />
        </Alert>
        <div className="mt-4">
          <Badge style={{ backgroundColor: getStateColor(), color: "#fff" }}>
            {t("ClientLayout.state.title")}: {getStateText()}
          </Badge>
        </div>

        <FileUploadAndComments
            viewType="public"
            isAdmin={false}
            communication={communication}
        />

        <Button
            type="button"
            className="ml-auto w-full md:w-fit flex gap-1.5 items-center"
            icon={CloudDownload}
            onClick={handleDownloadPDF}
        >
          {t("ClientLayout.forms.download")}
        </Button>
      </React.Fragment>
  );
};

export default CommunicationPage;
