import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Checkbox } from "@/components/ui/checkbox";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";
import { zodResolver } from "@hookform/resolvers/zod";
import {useEffect, useMemo, useState} from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z
    .object({
        name: z.string().min(1, { message: "Name is required" }),
        lastName: z.string().min(1, { message: "Last name is required" }),
        password: z
            .string()
            .min(8, { message: "Password must be at least 8 characters" })
            .regex(/[A-Z]/, { message: "Password must contain at least one uppercase letter" })
            .regex(/[a-z]/, { message: "Password must contain at least one lowercase letter" })
            .regex(/[0-9]/, { message: "Password must contain at least one number" })
            .regex(/[!@#$%^&*(),.?":{}|<>]/, { message: "Password must contain at least one punctuation mark" })
            .optional()
            .or(z.literal("")),
        confirmPassword: z.string().optional().or(z.literal("")),
        roles: z
            .array(z.number())
            .min(1, { message: "At least one role is required" }),
        isActive: z.boolean().default(false),
        email: z
            .string()
            .email({ message: "Invalid email address" })
            .min(1, { message: "Email is required" }),
    })
    .refine((data) => data.password === data.confirmPassword, {
        message: "Passwords don't match",
        path: ["confirmPassword"],
    });


const EditUserForm = ({ onClose }) => {
    const { t } = useTranslation();
    const rolOptions = useStore((state) => state.rolOptions);
    const userToUpdateData = useStore((state) => state.userToUpdateData);
    const updateUser = useStore((state) => state.updateUser);
    const [loading, setLoading] = useState(false);
    const loggedInUser = useStore((state) => state.loggedInUser);

    const filteredRoles = useMemo(() => {
        if (!loggedInUser || !Array.isArray(loggedInUser.roles)) {
            return [];
        }
        if (loggedInUser.roles.includes("superadmin")) {
            return rolOptions.filter((role) =>
                ["superadmin", "admin", "useredit"].includes(role.name)
            );
        }
        if (loggedInUser.roles.includes("admin")) {
            return rolOptions.filter((role) =>
                ["admin", "useredit"].includes(role.name)
            );
        }
        return [];
    }, [rolOptions, loggedInUser]);
    const form = useForm({
        resolver: zodResolver(formSchema),
        defaultValues: {
            name: "",
            lastName: "",
            password: "",
            confirmPassword: "",
            roles: [],
            isActive: false,
            email: "",
        },
    });

    useEffect(() => {
        if (userToUpdateData) {
            const userRoles = Array.isArray(userToUpdateData.roles)
                ? userToUpdateData.roles
                    .map(
                        (role) => filteredRoles.find((option) => option.name === role)?.id,
                    )
                    .filter((id) => id !== undefined)
                : [];

            form.reset({
                name: userToUpdateData.name || "",
                lastName: userToUpdateData.lastName || "",
                password: "",
                confirmPassword: "",
                roles: userRoles,
                isActive: userToUpdateData.isActive || false,
                email: userToUpdateData.email || "",
            });
        }
    }, [userToUpdateData, form, filteredRoles]);

    const onSubmit = async (values) => {
        setLoading(true);
        const { confirmPassword, password, ...dataToSubmit } = values;
        if (password) {
            dataToSubmit.password = password;
        }
        try {
            await updateUser(userToUpdateData.id, dataToSubmit);
            toast.success(t("alertDialog.toast.userUpdatedSuccess"));
            onClose();
        } catch (error) {
            console.error("Error al crear la pregunta:", error);
            toast.error(t("alertDialog.toast.userUpdatedError"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="w-full flex justify-center">
            <Card className="p-6 w-full bg-slate-50">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)}>
                        <h1 className="text-2xl font-light mb-4">
                            {t("MainLayout.users.updateUsers")}
                        </h1>
                        <FormField
                            control={form.control}
                            name="name"
                            render={({ field }) => (
                                <FormItem className="mb-4">
                                    <FormLabel>{t("forms.myData.name")}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={t("forms.myData.name")} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="lastName"
                            render={({ field }) => (
                                <FormItem className="mb-4">
                                    <FormLabel>{t("forms.myData.lastname")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={t("forms.myData.lastname")}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="roles"
                            render={({ field }) => (
                                <FormItem className="mb-4">
                                    <FormLabel>{t("forms.myData.roles")}</FormLabel>
                                    <div className="flex flex-wrap -mx-2">
                                        {filteredRoles.map((option) => (
                                            <div key={option.id} className="w-1/3 px-2 mb-2">
                                                <FormItem className="flex flex-row items-start space-x-3 space-y-0 mt-1">
                                                    <FormControl>
                                                        <Checkbox
                                                            checked={field.value.includes(option.id)}
                                                            onCheckedChange={(checked) => {
                                                                const updatedRoles = checked
                                                                    ? [...field.value, option.id]
                                                                    : field.value.filter((id) => id !== option.id);
                                                                field.onChange(updatedRoles);
                                                            }}
                                                        />
                                                    </FormControl>
                                                    <FormLabel className="font-normal">{option.name}</FormLabel>
                                                </FormItem>
                                            </div>
                                        ))}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />


                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field }) => (
                                <FormItem className="mb-4">
                                    <FormLabel>{t("forms.myData.email")}</FormLabel>
                                    <FormControl>
                                        <Input placeholder={t("forms.myData.email")} {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="password"
                            render={({ field }) => (
                                <FormItem className="mb-4">
                                    <FormLabel>{t("forms.myData.password")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={t("forms.myData.password")}
                                            type="password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="confirmPassword"
                            render={({ field }) => (
                                <FormItem className="mb-4">
                                    <FormLabel>{t("forms.myData.confirmPassword")}</FormLabel>
                                    <FormControl>
                                        <Input
                                            placeholder={t("forms.myData.confirmPassword")}
                                            type="password"
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="isActive"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-center space-x-2 mt-4 mb-4">
                                    <FormControl>
                                        <Checkbox
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                            id="isActive"
                                        />
                                    </FormControl>
                                    <FormLabel
                                        htmlFor="isActive"
                                        className="text-sm font-medium leading-none cursor-pointer"
                                    >
                                        {t("forms.myData.activeUser")}
                                    </FormLabel>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button
                            type="submit"
                            className="mt-4 bg-primary hover:bg-primary-600"
                        >
                            {loading ? (
                                <Loader style={"buttonLoader"} />
                            ) : (
                                t("buttonTexts.saveChanges")
                            )}
                        </Button>
                    </form>
                </Form>
            </Card>
        </section>
    );
};

export default EditUserForm;