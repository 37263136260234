import api from "../api.js";

//GET: LISTAR TODOS LOS TAGS
export const getTags = async () => {
  try {
    const response = await api.get("/tags");
    return response.data;
  } catch (error) {
    console.error("Error al obtener los tags:", error);
    throw error;
  }
};

//GET: OBTENER UN TAG ESPECÍFICO POR ID
export const getTagById = async (id) => {
  try {
    const response = await api.get(`/tags/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al obtener el tag:", error);
    throw error;
  }
};

//POST: CREAR UN TAG
export const createTag = async (data) => {
  try {
    const response = await api.post("/tags", data);
    return response.data;
  } catch (error) {
    console.error("Error al crear el tag:", error);
    throw error;
  }
};

//DELETE: ELIMINAR UN ESTADO
export const deleteTag = async (id) => {
  try {
    const response = await api.delete(`/tags/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error al eliminar el tag:", error);
    throw error;
  }
};

//PUT: ACTUALIZAR UN TAG
export const updateTag = async (id, data) => {
  try {
    const response = await api.put(`/tags/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error al actualizar el tag:", error);
    throw error;
  }
};

export default {
  getTags,
  createTag,
  deleteTag,
  getTagById,
  updateTag,
};
