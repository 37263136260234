import {
  createState,
  deleteState,
  getStateById,
  getStates,
  updateState,
} from "@/api/states/states.services";

export const statesSlice = (set, get) => ({
  states: [],
  updateModal: false,
  stateToUpdate: {},

  getStates: async () => {
    const data = await getStates();
    set({ states: data });
  },

  createState: async (state) => {
    await createState(state);
    get().getStates();
  },

  deleteState: async (id) => {
    await deleteState(id);
    get().getStates();
  },

  setUpdateModal: (value) => {
    set((state) => ({
      updateModal: value,
      stateToUpdate: value ? state.stateToUpdate : {},
    }));
  },

  getStateToUpdate: async (id) => {
    const data = await getStateById(id);
    set({ stateToUpdate: data });
    return data;
  },

  updateState: async (id, state) => {
    await updateState(id, state);
    get().getStates();
  },
});
