import { Button } from "@/components/ui/button";
import { useStore } from "@/store";
import { t } from "i18next";
import { ArrowUpDown, Pencil, Trash } from "lucide-react";
import { toast } from "sonner";
import { DataTableRowActions } from "../data-table-row-actions";

const handleDelete = (id) => {
  const deletePrivacyPolicy = useStore.getState().deletePrivacyPolicy;
  deletePrivacyPolicy(id);
  toast.success(t("alertDialog.toast.policyDeletedSuccess"));
};

const handleUpdate = async (id) => {
  const getPolicyToUpdate = useStore.getState().getPolicyToUpdate;
  const setUpdateModal = useStore.getState().setUpdateModal;
  try {
    await getPolicyToUpdate(id);
    setUpdateModal(true);
  } catch (error) {
    console.error("Error al cargar la política.", error);
  }
};

export const columns = [
  {
    accessorKey: "title",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.privacyPolicy.title")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("title")}</div>,
  },
  {
    accessorKey: "policyText",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.privacyPolicy.policyText")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("policyText")}</div>,
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={[
          {
            label: t("buttonTexts.delete"),
            onClick: (row) => handleDelete(row.original.id),
            icon: Trash,
          },
          {
            label: t("buttonTexts.edit"),
            onClick: (row) => handleUpdate(row.original.id),
            icon: Pencil,
          },
        ]}
      />
    ),
  },
];
