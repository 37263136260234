import { Button } from "@/components/ui/button";
import { useStore } from "@/store";
import { t } from "i18next";
import { ArrowUpDown, Pencil, Trash } from "lucide-react";
import { toast } from "sonner";
import { DataTableRowActions } from "../data-table-row-actions";

const handleDelete = async (id) => {
  try {
    const deleteState = useStore.getState().deleteState;
    await deleteState(id);
    toast.success(t("alertDialog.toast.stateDeletedSuccess"));
  } catch (error) {
    console.error("Error deleting state:", error);
    toast.error(t("alertDialog.toast.stateDeletedError"));
  }
};

const handleUpdate = async (id) => {
  const getStateToUpdate = useStore.getState().getStateToUpdate;
  const setUpdateModal = useStore.getState().setUpdateModal;
  try {
    await getStateToUpdate(id);
    setUpdateModal(true);
  } catch (error) {
    console.error("Error al cargar el estado.", error);
  }
};

export const columns = [
  {
    accessorKey: "name",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.states.name")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("name")}</div>,
  },
  {
    accessorKey: "type",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.states.type")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("type")}</div>,
  },
  {
    accessorKey: "color",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.states.color")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const colorValue = row.getValue("color");
      return (
        <div className="flex items-center ml-4">
          <div
            className="w-4 h-4 mr-2"
            style={{
              backgroundColor: `${colorValue}`,
              border: "1px solid #000",
            }}
          />
          <span>{`${colorValue}`}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "default",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.states.default")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("default")}</div>,
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={[
          {
            label: t("buttonTexts.delete"),
            onClick: (row) => handleDelete(row.original.id),
            icon: Trash,
          },
          {
            label: t("buttonTexts.edit"),
            onClick: (row) => handleUpdate(row.original.id),
            icon: Pencil,
          },
        ]}
      />
    ),
  },
];
