import api from "@/api/api";

const BASE_URL = "/configurable_texts";

export const getConfigurableTexts = async () => {
  return (await api.get(BASE_URL)).data;
};

export const getListConfiguration = async () => {
  const url = `${BASE_URL}?filters[type]=eq:list`;
  return (await api.get(url)).data;
};
export default { getConfigurableTexts, getListConfiguration };
