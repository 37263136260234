import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Loader from "@/components/utils/loader";
import { useStore } from "@/store";

import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const formSchema = z.object({
  text: z.string().min(1, { message: "Este campo es requerido" }),
  type: z.string().min(1, { message: "Este campo es requerido" }),
});

const UpdateTagForm = ({ onClose }) => {
  const tagToUpdate = useStore((tag) => tag.tagToUpdate);
  const updateTag = useStore((tag) => tag.updateTag);
  const [loading, setLoading] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      text: undefined,
      type: undefined,
    },
  });

  useEffect(() => {
    if (tagToUpdate) {
      //CONFIGURAR VALORES INICIALES
      form.reset({
        text: tagToUpdate.text,
        type: tagToUpdate.type,
      });
    }
  }, [tagToUpdate, form]);

  const onSubmit = async (values) => {
    setLoading(true);
    const updatedTag = {
      ...values,
    };
    try {
      await updateTag(tagToUpdate.id, updatedTag);
      toast.error(t("alertDialog.toast.tagUpdatedSuccess"));
      onClose();
    } catch (error) {
      console.error("Error al actualizar tag:", error);
      toast.error(t("alertDialog.toast.tagUpdatedError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="w-full flex justify-center">
      <Card className="p-6 w-full bg-slate-50">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="text"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("ClientLayout.forms.tags.tagName")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="mb-4">
                  <FormLabel>{t("ClientLayout.forms.tags.type")}</FormLabel>
                  <Select
                    onValueChange={(value) => {
                      form.setValue("type", value);
                    }}
                    value={form.getValues("type")}
                    disabled={true}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t(
                            "ClientLayout.forms.tags.defaultSelect",
                          )}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="complaint">
                        {t("ClientLayout.forms.tags.complaint")}
                      </SelectItem>
                      <SelectItem value="consultation">
                        {t("ClientLayout.forms.tags.consultation")}
                      </SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              className="mt-4 bg-primary hover:bg-primary-600"
            >
              {loading ? (
                <Loader style={"buttonLoader"} />
              ) : (
                t("buttonTexts.saveChanges")
              )}
            </Button>
          </form>
        </Form>
      </Card>
    </section>
  );
};

export default UpdateTagForm;
