import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Toaster } from "@/components/ui/sonner";
import { columns } from "@/components/ui/table/columns/questions";
import { DataTable } from "@/components/ui/table/data-table.jsx";
import Loader from "@/components/utils/loader.jsx";
import { useStore } from "@/store";
import { Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateQuestion from "./create-question.jsx";
import UpdateQuestion from "./update-question.jsx";

const FormsQuestions = ({ formType }) => {
  const { t } = useTranslation();
  const complaintQuestions = useStore((state) => state.complaintQuestions);
  const getComplaintQuestions = useStore(
    (state) => state.getComplaintQuestions,
  );
  const consultationQuestions = useStore(
    (state) => state.consultationQuestions,
  );
  const getConsultationQuestions = useStore(
    (state) => state.getConsultationQuestions,
  );

  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  const updateModal = useStore((state) => state.updateModal);
  const setUpdateModal = useStore((state) => state.setUpdateModal);
  const selectedLanguage = useStore((state) => state.selectedLanguage);

  useEffect(() => {
    try {
      if (formType === "complaint") {
        getComplaintQuestions();
      } else if (formType === "consultation") {
        getConsultationQuestions();
      }
    } catch (error) {
      console.error("Load questions error", error);
    } finally {
      setLoading(false);
    }
  }, [formType, getComplaintQuestions, getConsultationQuestions]);

  useEffect(() => {
    if (formType === "complaint") {
      setQuestions(complaintQuestions);
    } else if (formType === "consultation") {
      setQuestions(consultationQuestions);
    }
  }, [formType, complaintQuestions, consultationQuestions]);

  const formatQuestions = (questions) => {
    return (questions || []).map((question) => ({
      id: question?.id || null,
      questions:
          Array.isArray(question?.texts)
              ? question.texts.find((t) => t.language === selectedLanguage)?.text || "not text in this language"
              : "not text in this language",
      questionType: question?.type || "Unknown",
      possibleAnswers: Array.isArray(question?.options)
          ? question.options
          .map((option) =>
              Array.isArray(option?.text)
                  ? option.text.find((t) => t.language === selectedLanguage)?.text || null
                  : null,
          )
          .filter(Boolean)
          .join(", ") || ""
          : "",
      active: question?.active ? "Sí" : "No",
      screenNumber: question?.screen,
    }));
  };


  const formattedQuestions = formatQuestions(questions);

  const closeModal = () => {
    setUpdateModal(false);
  };

  return (
    <section className="w-full">
      <Toaster />

      <section className="w-full flex mb-3">
        <Dialog>
          <DialogTrigger asChild={true}>
            <Button className="mt-4 bg-primary hover:bg-primary-600">
              {t("buttonTexts.addNew")} <Plus className="ml-2" />
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                {t("MainLayout.questionForms.createQuestion")}
              </DialogTitle>
            </DialogHeader>
            <CreateQuestion formType={formType} />
          </DialogContent>
        </Dialog>
      </section>

      <section className="w-full flex">
        <Dialog
          open={updateModal}
          onOpenChange={(isOpen) => setUpdateModal(isOpen)}
        >
          <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>
                {t("MainLayout.questionForms.updateQuestion")}
              </DialogTitle>
            </DialogHeader>
            <UpdateQuestion formType={formType} closeModal={closeModal} />
          </DialogContent>
        </Dialog>
      </section>

      {loading ? (
        <div className="flex justify-center">
          <Loader style={"loader"} />
        </div>
      ) : (
        <DataTable
          data={formattedQuestions}
          columns={columns}
          mainFilterColumn="questions"
          textPlaceholder={t("filterTextPlaceholders.question")}
        />
      )}
    </section>
  );
};

export default FormsQuestions;
