import { Button } from "@/components/ui/button";
import { useStore } from "@/store";
import { t } from "i18next";
import { ArrowUpDown } from "lucide-react";
import { Pencil, Trash } from "lucide-react";
import { toast } from "sonner";
import { DataTableRowActions } from "../data-table-row-actions.jsx";

const handleDelete = (id) => {
  const deleteQuestion = useStore.getState().deleteQuestion;
  deleteQuestion(id);
  toast.success(t("alertDialog.toast.questionDeletedSuccess"));
};

const handleUpdate = async (id) => {
  const getQuestionToUpdateData = useStore.getState().getQuestionToUpdateData;
  const setUpdateModal = useStore.getState().setUpdateModal;
  try {
    await getQuestionToUpdateData(id);
    setUpdateModal(true);
  } catch (error) {
    console.error("Error al cargar la pregunta", error);
  }
};

export const columns = [
  {
    accessorKey: "questions",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.questions.questions")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("questions")}</div>,
  },
  {
    accessorKey: "questionType",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.questions.questionType")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="ml-4">{row.getValue("questionType")}</div>
    ),
  },
  {
    accessorKey: "possibleAnswers",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.questions.possibleAnswers")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="ml-4">{row.getValue("possibleAnswers")}</div>
    ),
  },
  {
    accessorKey: "active",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.questions.active")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="ml-4">{row.getValue("active")}</div>,
  },
  {
    accessorKey: "screenNumber",
    header: ({ column }) => {
      return (
        <Button
          className="uppercase text-xs"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          {t("headColumns.questions.screen")}
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="ml-4">{row.getValue("screenNumber")}</div>
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={[
          {
            label: "Delete",
            onClick: (row) => handleDelete(row.original.id),
            icon: Trash,
          },
          {
            label: "Edit",
            onClick: (row) => handleUpdate(row.original.id),
            icon: Pencil,
          },
        ]}
      />
    ),
  },
];
