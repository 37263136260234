import { Form } from "@/components/ui/form";
import {
  generateDefaultValues,
  generateZodSchema,
  processDefaultValues,
} from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export const SummaryForm = ({ communication }) => {
  const [questions, setQuestions] = useState([]);
  const [formSchema, setFormSchema] = useState(null);
  const [defaultValues, setDefaultValues] = useState(null);
  const { t } = useTranslation();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  useEffect(() => {
    if (communication?.form && communication.answers) {
      const formData = communication.form;

      const filteredQuestions = formData.questions.filter(
          (question) =>
              question.visibility?.summary === true &&
              question.type !== "password" &&
              question.type !== "file"
      );

      const sortedQuestions = filteredQuestions.sort(
          (a, b) => a.position - b.position
      );

      const questionsWithAnswers = sortedQuestions
          .map((question) => {
            const answer = communication.answers.find(
                (ans) => ans.question.id === question.id
            );

            let answerText = null;

            if (answer) {
              if (question.type === "select" || question.type === "radio") {
                const selectedOption = question.options.find(
                    (option) => option.value === answer.answerText
                );

                const esText = selectedOption?.text?.find(
                    (text) => text.language === "es"
                );

                if (esText) {
                  answerText = esText.text;
                } else {
                  return null;
                }
              } else if (question.type === "terms") {
                answerText = answer.answerText === "1" ? "Sí" : "No";
              } else {
                answerText = answer.answerText;
              }
            }

            return {
              ...question,
              answer: answerText,
            };
          })
          .filter((question) => question !== null);

      const questionsWithValidAnswers = questionsWithAnswers.filter(
          (question) => question.answer !== null && question.answer !== ""
      );

      setQuestions(questionsWithValidAnswers);
      setFormSchema(generateZodSchema(questionsWithValidAnswers, t));
      const processedValues = processDefaultValues(formData);
      const defaultValues =
          processedValues || generateDefaultValues(questionsWithValidAnswers);
      setDefaultValues(defaultValues);
    }
  }, [t, communication]);

  useEffect(() => {
    if (formSchema && defaultValues) {
      form.reset(defaultValues);
    }
  }, [formSchema, defaultValues]);

  return (
      questions &&
      questions.length > 0 && (
          <div className="border border-dashed rounded-lg p-6 border-primary animate-fade-in">
            <Form {...form}>
              {questions.map((question) => (
                  <div key={question.id}>
                    <p>
                      <strong>{question.texts[0]?.text || "Pregunta"}</strong>
                    </p>
                    <p>{question.answer}</p>
                  </div>
              ))}
            </Form>
          </div>
      )
  );
};
