import api from "../api.js";

export const getLanguages = async () => {
  try {
    const response = await api.get("/languages");
    return response.data;
  } catch (error) {
    console.error("Error al obtener los idiomas:", error);
    throw error;
  }
};

export const createLanguage = async (language) => {
  try {
    const response = await api.post("/languages", language);
    return response.data;
  } catch (error) {
    throw error.response
      ? error.response.data
      : new Error("Error al actualizar");
  }
};

export const removeLanguage = async (id) => {
  try {
    const response = await api.delete(`/languages/${id}`);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Error en el login");
  }
};

export const updateLanguage = async (language) => {
  const { id, ...langProps } = language;
  try {
    const response = await api.put(`/languages/${id}`, langProps);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Error en el login");
  }
};
